import { useContext, useEffect, useState } from 'react';
import { ContextApp } from '../contexts/ContextApp';
import { openDatabase } from './IndexedDB';

export interface IOfflineData {
  data: any;
  key: string;
  timestamp: Date;
}

export const useOfflineFunctions = () => {
  const [canUseOfflineFunctions] = useState(
    // Intencionalmente es un estado por si queremos manejar el uso de estas funciones de forma programatica. Incluso podria pensarse en tener su propio contexto
    !!window.indexedDB,
  );
  const [databaseInitializing, setDatabaseInitializing] = useState(true);
  const { indexedDatabaseRef } = useContext(ContextApp);

  /**
   * Permite que la información no sea eliminada del store por recolectores de basura
   */
  const persistData = async () => {
    if (navigator.storage) {
      const isDataPersisted = await navigator.storage.persisted();
      if (!isDataPersisted && navigator.storage.persist) {
        await navigator.storage.persist();
      }
    }
  };

  const initIndexedDB = async () => {
    try {
      if (canUseOfflineFunctions) {
        const db = await openDatabase();

        await persistData();

        indexedDatabaseRef.current = db;
      } else {
        console.error("Can't use indexedDB");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setDatabaseInitializing(false);
    }
  };

  useEffect(() => {
    if (!indexedDatabaseRef.current) {
      initIndexedDB();
    } else {
      setDatabaseInitializing(false);
    }
  }, []);

  return { canUseOfflineFunctions, indexedDatabaseRef, databaseInitializing };
};

import { createContext, MutableRefObject } from 'react';
import { IConfigApp, IConfig, IEnvironment } from '../interfaces/environment';
import { IUser, IUserFirebase } from '../interfaces/user';
import { IRole } from '../interfaces/role';
import { IDBPDatabase } from 'idb';
import { TParameter, TReturn } from '../i18n/i18n.model';
import { StringMap, TOptions } from 'i18next';
import { Language } from '../interfaces';
const configApp: any = {};
const environment: any = {};
const config: any = {};
const roles: any[] = [];
const functions: string[] = [];

interface IContextApp {
  configApp: IConfigApp;
  setUser: Function;
  user?: IUser;
  setLoading: Function;
  loading: boolean;
  setLoadingMessage: Function;
  loadingMessage: string;
  setShowLoading: Function;
  showLoading: boolean;
  environment: IEnvironment;
  setEnvironment: Function;
  config: IConfig;
  setConfig: Function;
  roles: IRole[];
  setRoles: Function;
  functions: string[];
  setFunctions: Function;
  dataLoaded: boolean;
  setDataLoaded: Function;
  checkAuth: () => Promise<void>;
  mainMenuCollapsed: boolean;
  setMainMenuCollapsed: Function;
  menuBreakpointCollapsed: boolean;
  setMenuBreakpointCollapsed: Function;
  sessionExpired: boolean;
  setSessionExpired: Function;
  languages: Language[];
  userDataFirebase?: IUserFirebase;
  setUserDataFirebase: Function;
  pictureProfileUrl: string;
  setPictureProfileUrl: Function;
  show2FAReminder: boolean;
  setShow2FAReminder: Function;
  indexedDatabaseRef: MutableRefObject<IDBPDatabase<unknown> | null>;
  t: <S extends string>(
    p: TParameter<S>,
    options?: TOptions<StringMap>,
  ) => TReturn<S>;
  switchRole: Function;
}

const context: IContextApp = {
  configApp,
  setUser: () => {},
  user: undefined,
  setLoading: () => {},
  loading: false,
  setLoadingMessage: () => {},
  loadingMessage: '',
  setShowLoading: () => {},
  showLoading: true,
  environment,
  setEnvironment: () => {},
  config,
  setConfig: () => {},
  setRoles: () => {},
  setFunctions: () => {},
  roles,
  functions,
  dataLoaded: false,
  setDataLoaded: () => {},
  checkAuth: async () => {},
  mainMenuCollapsed: false,
  setMainMenuCollapsed: () => {},
  menuBreakpointCollapsed: false,
  setMenuBreakpointCollapsed: () => {},
  sessionExpired: false,
  setSessionExpired: () => {},
  languages: [],
  setUserDataFirebase: () => {},
  userDataFirebase: undefined,
  pictureProfileUrl: '',
  setPictureProfileUrl: () => {},
  show2FAReminder: false,
  setShow2FAReminder: () => {},
  indexedDatabaseRef: { current: null },
  t: () => '' as any,
  switchRole: () => {},
};

export const ContextApp = createContext(context);

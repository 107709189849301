import { Button } from 'antd';
import { FilterOutlined } from '@ant-design/icons';
import './ButtonFilter.less';

interface IButtonFilter {
  setOpen: (value: boolean) => void;
}

const ButtonFilter = (props: IButtonFilter) => {
  const { setOpen } = props;
  return (
    <div className="button-filter-container">
      <Button
        size="large"
        type="text"
        className="button-filter-container__button"
        onClick={() => setOpen(true)}
      >
        <FilterOutlined />
      </Button>
    </div>
  );
};
export default ButtonFilter;

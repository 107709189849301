export const DETAILED_REPORT = `
query detailedReport($input: DetailedReportInput!) {
  detailedReport(input: $input) {
    __typename
    ... on ResponseMessage {
      status
      response
    }
    ... on ResultError {
      status_code
      message
    }
  }
}

`;

// Import the functions you need from the SDKs you need
import firebase from 'firebase/compat';
import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import {
  getMessaging,
  getToken,
  MessagePayload,
  onMessage,
} from 'firebase/messaging';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

// Initialize Firebase
const initializeFirebaseApp = () => {
  return firebase.initializeApp(firebaseConfig);
};

const firebaseApp = firebase;

const getAuthFirebase = (firebaseApp: any) => {
  return getAuth(firebaseApp);
};

const getMessagingFirebase = (_firebaseApp: any) => {
  return getMessaging(_firebaseApp);
};

const signInWithGoogle = async () => {
  const firebaseApp = initializeFirebaseApp();
  const auth = getAuthFirebase(firebaseApp);
  const provider = new GoogleAuthProvider();
  try {
    const res = await signInWithPopup(auth, provider);
    const user = res.user;
    return user;
  } catch (err: any) {
    console.error(err);
  }
};

const getMessagingToken = async () => {
  const app = initializeFirebaseApp();
  const messaging = getMessagingFirebase(app);
  return getToken(messaging, {
    vapidKey: process.env.REACT_APP_FCM_PUBLIC_VAPID_KEY,
  });
};

const registerOnMessageHandler = (
  handler: (payload: MessagePayload) => void,
) => {
  const app = initializeFirebaseApp();
  const messaging = getMessagingFirebase(app);
  return onMessage(messaging, (payload) => {
    handler(payload);
  });
};

const logoutFirebase = () => {
  initializeFirebaseApp();
  firebaseApp
    .auth()
    .signOut()
    .catch(() => {});
};
export {
  initializeFirebaseApp,
  firebaseApp,
  getAuthFirebase,
  getMessagingFirebase,
  logoutFirebase,
  firebaseConfig,
  signInWithGoogle,
  getMessagingToken,
  registerOnMessageHandler,
};

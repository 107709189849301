export interface IPropertyType {
  id: number;
  name: string;
  active: boolean;
}

export const DEFAULT_ORDER_BY_PROPERTY_TYPES = {
  field: 'name',
  direction: 'asc',
};

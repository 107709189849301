export const TAG = `
query tag(
    $id: number! 
){
    tag(
        id: $id
    )
{
    __typename
    ... on Tag{
        id
        name
        entities
    }
    ... on ResultError{
        status_code
        message
    }
}
}
`;

export const TAGS = `
query tags(
    $filter:  FilterTagInput,
    $orderBy:  TagOrderInput,
    $skip: Int,
    $take: Int,
    $searchText: String,
){
    tags(
        filter: $filter
        orderBy: $orderBy
        skip: $skip
        take: $take
        searchText: $searchText
    )
{
    __typename
    ... on Tag{
        id
        name
        entities
    }
    ... on ResultError{
        status_code
        message
    }
}
}

`;

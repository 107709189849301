export const REGISTER_DEVICE = `
mutation registerDevice($token:String!){
  registerDevice(
    token: $token
  ){
    __typename
    ... on FirebaseMessagingRegister{
      id
    }
    ... on ResultError {
      status_code
      message
}
  }
}`;

export const DELETE_REGISTER_DEVICE = `
mutation deleteRegisterDevice($id:Int!) {
  deleteRegisterDevice(id: $id) {
    __typename
    ... on FirebaseMessagingRegister {
      id
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const CREATE_COMPLAINT = `
mutation createComplaint($data:  ComplaintCreateInput!) {
    createComplaint(data: $data) {
      __typename
      ... on ResponseMessage {
        response
        status
      }
      ... on ResultError {
        status_code
        message
      }
    }
  }
`;

export const RESOLVE_COMPLAINT = `
mutation resolveComplaint($complaint_id: Int!){
  resolveComplaint(complaint_id: $complaint_id){
    __typename
    ...on ResponseMessage{
      status
      response
    }
    ...on ResultError{
      status_code
      message
    }
  }
}
`;

export const CANCEL_COMPLAINT = `
mutation cancelComplaint($complaint_id: Int!){
  cancelComplaint(complaint_id: $complaint_id){
    __typename
    ...on ResponseMessage{
      status
      response
    }
    ...on ResultError{
      status_code
      message
    }
  }
}
`;

export const REQUEST_ADMIN_COMPLAINT = `
mutation requestAdminComplaint($cua_id: Int!, $complaint_id: Int!){
  requestAdminComplaint(cua_id:$cua_id, complaint_id:$complaint_id){
    __typename
    ...on ResponseMessage{
      status
      response
    }
    ...on ResultError{
      status_code
      message
    }
  }
}
`;

export const CHANGE_USER_ROLE_BY_COMPLAINT = `
mutation changeUserRoleByComplaint($input: ChangeRoleInput!){
  changeUserRoleByComplaint(input:$input){
    __typename
    ...on ResponseMessage{
      status
     	response
    }
    ...on ResultError{
      status_code
      message
    }
  }
}
`;

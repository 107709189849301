export const UPDATE_PERSON = `
mutation updatePerson($input: UpdatePersonInput!){
    updatePerson(
      input: $input,
    ){
      __typename
      ...on Person{
        id
      }
      ...on ResultError{
        status_code
        message
      }    
    }
  }
`;

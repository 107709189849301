export const COMPLAINT_REASON_LIST = `
query complaintReasonList(
    $filter: ComplaintReasonFilterInput, 
    $orderBy: ComplaintReasonOrderInput, 
    $skip: Int, 
    $take: Int
  ){
  complaintReasonList(
    filter:$filter, 
    orderBy:$orderBy, 
    skip:$skip, 
    take:$take
  ){
    __typename
    ...on ComplaintReason{
      id
      name
    }
    ...on ResultError{
      status_code
      message
    }
  }
}
`;

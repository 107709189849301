import { PROPERTY_ATTRIBUTES } from './PropertyAttribute';
import { print } from 'graphql';
import {
  USERS,
  AUTH,
  LOGOUT,
  RESET_PASSWORD,
  VERIFY_RECOVERY_PASSWORD_TOKEN,
  USERS_COUNT_BY_ROLE,
  USERS_COUNT,
  GET_PROFILE_PICTURE,
  GET_PROFILE_FILE,
  ACCEPT_TERMS_AND_CONDITIONS,
} from './User';
import { COMPANY, COMPANIES } from './Company';
import { SETTINGS, SETTING_KEY } from './AppSetting';
import { LANGUAGES } from './Language';
import { ROLE, ROLES } from './Role';
import { PERMISSIONS } from './Permission';
import { ROLE_PERMISSION, ROLE_PERMISSIONS } from './RolePermission';
import { EXPORT_OPERATIONS, OPERATIONS } from './Operation';
import { TAGS } from './Tag';
import { GET_SIGNATURE } from './Signature';
import { IQuery } from '../GraphqlService';
import { ObjectStore } from '../../../enums/EnumsValues';
import {
  GET_TENANT_PICTURE,
  TENANT,
  TENANTS,
  TENANT_COUNT,
  USER_TENANT,
} from './Tenant';
import { VERIFY_COMPLETE_REGISTER_BY_EMAIL_TOKEN } from './VeryTokens';
import { PERSON_IS_EDITABLE } from './Person';
import {
  GET_DOCUMENTATION_FILE,
  GET_DOCUMENTATION_LINK,
} from './Documentation';
import { PROVINCES } from './Province';
import { LOCATIONS } from './Location';
import { PROPERTY_TYPES } from './PropertyType';
import { PROPERTY, PROPERTY_LIST } from './Property';
import { CHECK_INVITATION, INVITATIONS } from './Invitation';
import {
  CUA,
  CUAS,
  CUA_STATUS_AND_ACTIONS,
  CUA_STATUS_SUMMARY,
  GET_PERSON_INFO_BY_CUA,
} from './Cua';
import { PROPERTY_STATUS_CUA } from './PropertyStatusCua';
import { PAYMENT_FREQUENCIES } from './PaymentFrenquency';
import { NOTIFICATIONS } from './Notification';
import { LOGS } from './Log';
import {
  COMPLAINT,
  COMPLAINT_ACTIONS,
  COMPLAINT_LIST,
  PERSON_HAS_COMPLAINTS,
} from './Complaints';
import { COMPLAINT_REASON_LIST } from './ComplaintReason';
import { CUA_STATUS_LIST } from './CuaStatus';
import { DETAILED_REPORT } from './DetailedReport';
import { COMPLAINT_COMMENT_LIST } from './ComplaintComment';

let Query = {
  authenticate: { name: 'authenticate', gql: AUTH },
  companies: {
    name: 'companies',
    gql: COMPANIES,
    objectStore: ObjectStore.Company,
  },
  company: { name: 'company', gql: COMPANY },
  getAppSettingByKey: { name: 'getAppSettingByKey', gql: SETTING_KEY },
  getAppSettings: { name: 'getAppSettings', gql: SETTINGS },
  languages: { name: 'languages', gql: print(LANGUAGES) },
  logout: { name: 'logout', gql: LOGOUT },
  operations: {
    name: 'operations',
    gql: OPERATIONS,
    objectStore: ObjectStore.Operation,
  },
  permissions: { name: 'permissions', gql: PERMISSIONS },
  resetPassword: { name: 'resetPassword', gql: RESET_PASSWORD },
  role: { name: 'role', gql: ROLE },
  roles: { name: 'roles', gql: ROLES },
  rolePermission: { name: 'rolePermission', gql: ROLE_PERMISSION },
  rolePermissions: { name: 'rolePermissions', gql: ROLE_PERMISSIONS },
  users: { name: 'users', gql: USERS },
  userCount: { name: 'userCount', gql: USERS_COUNT },
  userCountByRole: { name: 'userCountByRole', gql: USERS_COUNT_BY_ROLE },
  verifyRecoveryPasswordToken: {
    name: 'verifyRecoveryPasswordToken',
    gql: VERIFY_RECOVERY_PASSWORD_TOKEN,
  },
  getProfilePicture: { name: 'getProfilePicture', gql: GET_PROFILE_PICTURE },
  getProfileFile: { name: 'getProfileFile', gql: GET_PROFILE_FILE },
  getSignature: { name: 'getSignature', gql: GET_SIGNATURE },
  tenants: { name: 'tenants', gql: TENANTS },
  findManyUserTenants: { name: 'findManyUserTenants', gql: USER_TENANT },
  tenant: { name: 'tenant', gql: TENANT },
  getTenantPicture: {
    name: 'getTenantPicture',
    gql: GET_TENANT_PICTURE,
  },
  tenantCount: { name: 'tenantCount', gql: TENANT_COUNT },
  exportOperations: { name: 'exportOperations', gql: EXPORT_OPERATIONS },
  verifyCompleteRegisterByEmailToken: {
    name: 'verifyCompleteRegisterByEmailToken',
    gql: VERIFY_COMPLETE_REGISTER_BY_EMAIL_TOKEN,
  },
  tags: { name: 'tags', gql: TAGS },
  acceptTermsAndConditions: {
    name: 'acceptTermsAndConditions',
    gql: ACCEPT_TERMS_AND_CONDITIONS,
  },
  personIsEditable: {
    name: 'personIsEditable',
    gql: PERSON_IS_EDITABLE,
  },

  getDocumentationFile: {
    name: 'getDocumentationFile',
    gql: GET_DOCUMENTATION_FILE,
  },

  provinces: {
    name: 'provinces',
    gql: PROVINCES,
  },

  locations: {
    name: 'locations',
    gql: LOCATIONS,
  },

  propertyTypes: {
    name: 'propertyTypes',
    gql: PROPERTY_TYPES,
  },

  propertyList: {
    name: 'propertyList',
    gql: PROPERTY_LIST,
  },

  property: {
    name: 'property',
    gql: PROPERTY,
  },

  invitations: {
    name: 'invitations',
    gql: INVITATIONS,
  },
  checkInvitation: {
    name: 'checkInvitation',
    gql: CHECK_INVITATION,
  },
  cua: {
    name: 'cua',
    gql: CUA,
  },
  propertyAttributes: {
    name: 'propertyAttributes',
    gql: PROPERTY_ATTRIBUTES,
  },
  propertyStatusCua: {
    name: 'propertyStatusCua',
    gql: PROPERTY_STATUS_CUA,
  },
  paymentFrequencies: {
    name: 'paymentFrequencies',
    gql: PAYMENT_FREQUENCIES,
  },
  notifications: {
    name: 'notifications',
    gql: NOTIFICATIONS,
  },
  cuaStatusSummary: {
    name: 'cuaStatusSummary',
    gql: CUA_STATUS_SUMMARY,
  },
  getPersonInfoByCua: {
    name: 'getPersonInfoByCua',
    gql: GET_PERSON_INFO_BY_CUA,
  },
  cuas: {
    name: 'cuas',
    gql: CUAS,
  },
  logs: {
    name: 'logs',
    gql: LOGS,
  },
  complaintList: {
    name: 'complaintList',
    gql: COMPLAINT_LIST,
  },
  cuaStatusAndActions: {
    name: 'cua',
    gql: CUA_STATUS_AND_ACTIONS,
  },
  complaintReasonList: {
    name: 'complaintReasonList',
    gql: COMPLAINT_REASON_LIST,
  },
  cuaStatusList: {
    name: 'cuaStatusList',
    gql: CUA_STATUS_LIST,
  },
  complaint: {
    name: 'complaint',
    gql: COMPLAINT,
  },
  complaintActions: {
    name: 'complaintActions',
    gql: COMPLAINT_ACTIONS,
  },
  personHasComplaints: {
    name: 'personHasComplaints',
    gql: PERSON_HAS_COMPLAINTS,
  },
  detailedReport: {
    name: 'detailedReport',
    gql: DETAILED_REPORT,
  },
  complaintCommentList: {
    name: 'complaintCommentList',
    gql: COMPLAINT_COMMENT_LIST,
  },
  getDocumentationLink: {
    name: 'getDocumentationLink',
    gql: GET_DOCUMENTATION_LINK,
  },
};

// Check type maintaining inferred type
const checkTypeQuery = <Obj extends Record<string, IQuery>>(obj: Obj) => obj;
Query = checkTypeQuery(Query);

export { Query };

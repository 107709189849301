import { UPSERT_IDENTITY_PICTURES } from './Documentation';
import { LOGIN, LOGIN_FIREBASE } from '../query/User';
import {
  DELETE_USER,
  RESET_PASSWORD,
  SET_PASSWORD,
  UPDATE_USER,
  CREATE_USER,
  UPSERT_USER_ROLE,
  UPSERT_USER_FIREBASE,
  CREATE_PROFILE_PICTURE,
  UPDATE_PROFILE_PICTURE,
  DELETE_PROFILE_PICTURE,
  UPDATE_MY_PASSWORD,
  COMPLETE_REGISTER,
  RESEND_VALIDATE_TOKEN,
  CREATE_USER_PUBLIC,
  CHANGE_LAST_ROLE_ID,
} from './User';
import { CREATE_COMPANY, UPDATE_COMPANY, DELETE_COMPANY } from './Company';
import { UPDATE_SETTING } from './AppSetting';
import { CREATE_ROLE, DELETE_ROLE, UPDATE_ROLE } from './Role';
import { UPSERT_ROLE_PERMISSION } from './RolePermission';
import {
  CREATE_OPERATION,
  DELETE_OPERATION,
  UPDATE_OPERATION,
} from './Operation';
import { REFRESH_TOKEN } from './RefreshToken';
import { UPDATE_PERMISSION } from './Permission';
import {
  DISABLE_TWO_FACTOR_AUTH,
  GENERATE_TOTP_URL,
  TWO_FACTOR_AUTH,
} from './Totp2FA';
import { CREATE_SIGNATURE, DELETE_SIGNATURE } from './Signature';
import {
  CREATE_TENANT,
  DELETE_TENANT,
  UPDATE_TENANT,
  UPSERT_USERTENANT,
} from './Tenant';
import { CREATE_TAG } from './Tag';
import { UPDATE_PERSON } from './Person';
import {
  CREATE_PROPERTY,
  DELETE_PROPERTY,
  DELETE_PROPERTY_MANAGER,
  UPDATE_PROPERTY,
} from './Property';
import {
  ACCEPT_INVITATION,
  CREATE_INVITATION,
  DELETE_INVITATION,
  REJECT_INVITATION,
  SEND_CUA_INVITATION_TO_TENANT,
  TENANT_ACCEPT_CUA,
  TENANT_REJECT_CUA,
} from './Invitation';
import {
  APPROVE_CUA,
  CREATE_CUA,
  CREATE_PERSON_CUA,
  CREATE_PROPERTY_STATUS,
  DELETE_CUA,
  REGISTER_AGREEMENT_DATA,
  REJECT_CUA,
} from './Cua';
import { DELETE_NOTIFICATION } from './Notification';
import { DELETE_REGISTER_DEVICE, REGISTER_DEVICE } from './FirebaseMessaging';
import {
  CANCEL_COMPLAINT,
  CHANGE_USER_ROLE_BY_COMPLAINT,
  CREATE_COMPLAINT,
  REQUEST_ADMIN_COMPLAINT,
  RESOLVE_COMPLAINT,
} from './Complaint';
import { CREATE_COMMENT_COMPLAINT } from './CommentComplaint';

const Mutation = {
  createCompany: { name: 'createCompany', gql: CREATE_COMPANY },
  createOperation: { name: 'createOperation', gql: CREATE_OPERATION },
  createRole: { name: 'createRole', gql: CREATE_ROLE },
  createUser: { name: 'createUser', gql: CREATE_USER },
  deleteCompany: { name: 'deleteCompany', gql: DELETE_COMPANY },
  deleteOperation: { name: 'deleteOperation', gql: DELETE_OPERATION },
  deleteRole: { name: 'deleteRole', gql: DELETE_ROLE },
  deleteUser: { name: 'deleteUser', gql: DELETE_USER },
  login: { name: 'login', gql: LOGIN },
  loginFirebase: { name: 'loginFirebase', gql: LOGIN_FIREBASE },
  resetPassword: { name: 'resetPassword', gql: RESET_PASSWORD },
  setPassword: { name: 'setPassword', gql: SET_PASSWORD },
  updateAppSetting: { name: 'updateAppSetting', gql: UPDATE_SETTING },
  updateCompany: { name: 'updateCompany', gql: UPDATE_COMPANY },
  updateOperation: { name: 'updateOperation', gql: UPDATE_OPERATION },
  updatePermission: { name: 'updatePermission', gql: UPDATE_PERMISSION },
  updateRole: { name: 'updateRole', gql: UPDATE_ROLE },
  updateUser: { name: 'updateUser', gql: UPDATE_USER },
  upsertUserRole: { name: 'upsertUserRole', gql: UPSERT_USER_ROLE },
  upsertRolePermission: {
    name: 'upsertRolePermission',
    gql: UPSERT_ROLE_PERMISSION,
  },
  refreshToken: { name: 'refreshToken', gql: REFRESH_TOKEN },
  upsertUserFirebase: { name: 'upsertUserFirebase', gql: UPSERT_USER_FIREBASE },
  createProfilePicture: {
    name: 'createProfilePicture',
    gql: CREATE_PROFILE_PICTURE,
  },
  updateProfilePicture: {
    name: 'updateProfilePicture',
    gql: UPDATE_PROFILE_PICTURE,
  },
  deleteProfilePicture: {
    name: 'deleteProfilePicture',
    gql: DELETE_PROFILE_PICTURE,
  },
  updateMyPassword: {
    name: 'updateMyPassword',
    gql: UPDATE_MY_PASSWORD,
  },
  disableTwoFactorAuth: {
    name: 'disableTwoFactorAuth',
    gql: DISABLE_TWO_FACTOR_AUTH,
  },
  generateTotpUrl: {
    name: 'generateTotpUrl',
    gql: GENERATE_TOTP_URL,
  },
  twoFactorAuth: {
    name: 'twoFactorAuth',
    gql: TWO_FACTOR_AUTH,
  },
  createSignature: {
    name: 'createSignature',
    gql: CREATE_SIGNATURE,
  },
  deleteSignature: {
    name: 'deleteSignature',
    gql: DELETE_SIGNATURE,
  },
  upsertUserTenant: {
    name: 'upsertUserTenant',
    gql: UPSERT_USERTENANT,
  },
  createTenant: {
    name: 'createTenant',
    gql: CREATE_TENANT,
  },
  deleteTenants: {
    name: 'deleteTenants',
    gql: DELETE_TENANT,
  },
  updateTenant: {
    name: 'updateTenant',
    gql: UPDATE_TENANT,
  },
  completeRegister: {
    name: 'completeRegister',
    gql: COMPLETE_REGISTER,
  },
  resendValidateToken: {
    name: 'resendValidateToken',
    gql: RESEND_VALIDATE_TOKEN,
  },
  createUserPublic: {
    name: 'createUserPublic',
    gql: CREATE_USER_PUBLIC,
  },
  createTag: {
    name: 'createTag',
    gql: CREATE_TAG,
  },
  updatePerson: {
    name: 'updatePerson',
    gql: UPDATE_PERSON,
  },

  upsertIdentityPictures: {
    name: 'upsertIdentityPictures',
    gql: UPSERT_IDENTITY_PICTURES,
  },

  changeLastRoleId: {
    name: 'changeLastRoleId',
    gql: CHANGE_LAST_ROLE_ID,
  },

  createProperty: {
    name: 'createProperty',
    gql: CREATE_PROPERTY,
  },
  updateProperty: {
    name: 'updateProperty',
    gql: UPDATE_PROPERTY,
  },
  createInvitation: {
    name: 'createInvitation',
    gql: CREATE_INVITATION,
  },
  deleteProperty: {
    name: 'deleteProperty',
    gql: DELETE_PROPERTY,
  },
  deleteInvitation: {
    name: 'deleteInvitation',
    gql: DELETE_INVITATION,
  },
  deletePropertyManager: {
    name: 'deletePropertyManager',
    gql: DELETE_PROPERTY_MANAGER,
  },
  acceptInvitation: {
    name: 'acceptInvitation',
    gql: ACCEPT_INVITATION,
  },
  rejectInvitation: {
    name: 'rejectInvitation',
    gql: REJECT_INVITATION,
  },
  createCua: {
    name: 'createCua',
    gql: CREATE_CUA,
  },
  createPropertyStatus: {
    name: 'createPropertyStatus',
    gql: CREATE_PROPERTY_STATUS,
  },
  deleteCua: {
    name: 'deleteCua',
    gql: DELETE_CUA,
  },
  deleteNotification: {
    name: 'deleteNotification',
    gql: DELETE_NOTIFICATION,
  },
  registerAgreementData: {
    name: 'registerAgreementData',
    gql: REGISTER_AGREEMENT_DATA,
  },
  createPersonCua: {
    name: 'createPersonCua',
    gql: CREATE_PERSON_CUA,
  },
  sendCuaInvitationToTenant: {
    name: 'sendCuaInvitationToTenant',
    gql: SEND_CUA_INVITATION_TO_TENANT,
  },
  tenantAcceptCua: {
    name: 'tenantAcceptCua',
    gql: TENANT_ACCEPT_CUA,
  },
  tenantRejectsCua: {
    name: 'tenantRejectsCua',
    gql: TENANT_REJECT_CUA,
  },
  approveCua: {
    name: 'approveCua',
    gql: APPROVE_CUA,
  },
  rejectCua: {
    name: 'rejectCua',
    gql: REJECT_CUA,
  },
  registerDevice: {
    name: 'registerDevice',
    gql: REGISTER_DEVICE,
  },
  deleteRegisterDevice: {
    name: 'deleteRegisterDevice',
    gql: DELETE_REGISTER_DEVICE,
  },
  createComplaint: {
    name: 'createComplaint',
    gql: CREATE_COMPLAINT,
  },
  resolveComplaint: {
    name: 'resolveComplaint',
    gql: RESOLVE_COMPLAINT,
  },
  cancelComplaint: {
    name: 'cancelComplaint',
    gql: CANCEL_COMPLAINT,
  },
  createComplaintComment: {
    name: 'createComplaintComment',
    gql: CREATE_COMMENT_COMPLAINT,
  },
  requestAdminComplaint: {
    name: 'requestAdminComplaint',
    gql: REQUEST_ADMIN_COMPLAINT,
  },

  changeUserRoleByComplaint: {
    name: 'changeUserRoleByComplaint',
    gql: CHANGE_USER_ROLE_BY_COMPLAINT,
  },
};

export { Mutation };

export const COMPLAINT_LIST = `
query complaintList(
  $filter: ComplaintFilterInput, 
  $orderBy: ComplaintOrderInput, 
  $skip: Int, 
  $take: Int
){
complaintList(
  filter: $filter, 
  orderBy:$orderBy, 
  skip: $skip, 
  take: $take
){
  __typename
  ...on Complaint{
    id
    complaint_status_id
    complaint_status{
      name
    }
    admin_intervention
    defendant_role_id
    complaint_reason{
      name
      description
    }
    due_date
    closed_date
  }
  ...on ResultError{
    status_code
    message
  }
}
}
`;

export const COMPLAINT = `
query complaint($id: Int!){
  complaint(id:$id){
    __typename
    ...on Complaint{
      id
      cua_id
      reason_id
      description
      amount
      cbu_alias
      account_owner
      closed_date
      due_date
      complainant_role_id
      defendant_role_id
      user_id_resolves
      complaint_status_id
      admin_intervention
      complaint_status{
        id
        name
      }
      complaint_reason{
        id
        name        
      }
      created_by
      createdBy {
        person {
          name
          lastname
          person_type
        }
      }
      userResolves {
        person {
          name
          lastname
          person_type
        }
      }
     	complaint_documentation{
      	id
      	documentation_id      
        documentation{
          filename
          mimetype
        }
    	} 
    }
    ...on ResultError{
      status_code
      message
    }
  }
}
`;

export const COMPLAINT_ACTIONS = `
query complaintActions($complaint_id: Int!){
  complaintActions(complaint_id:$complaint_id){
    __typename
    ...on ComplaintAction{
      can_read
      can_comment
      can_resolve
      get_intervention
      can_delete      
    }
  }
}
`;

export const PERSON_HAS_COMPLAINTS = `
query personHasComplaints($person_id: Int!) {
  personHasComplaints(person_id: $person_id) {
    __typename
    ... on ResponseMessage {
      status
      response
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

import { ReactNode } from 'react';
import './CardGray.less';
interface ICardGrayProps {
  children: ReactNode;
  className?: string;
  onClick?: () => void;
}
const CardGray = ({ children, className, onClick }: ICardGrayProps) => {
  return (
    <div
      onClick={() => onClick && onClick()}
      className={`card-gray ${className}`}
    >
      {children}
    </div>
  );
};
export default CardGray;

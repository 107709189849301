export const COMPLAINT_COMMENT_LIST = `
query complaintCommentList(
  $orderBy: ComplaintCommentOrderInput, 
  $skip:Int, 
  $take:Int, 
  $filter:FilterComplaintComment!
){
complaintCommentList(
  orderBy:$orderBy, 
  skip:$skip, 
  take:$take, 
  filter:$filter
){
  __typename
  ...on ComplaintComment{
    id
    description
    createdBy{
      id
      person{
        name
        lastname        
      }
    }
    role{
      id
      name
    }
    complaint_comment_documentation{
      id
      documentation_id
      documentation{
        filename
        mimetype
      }
    }
    its
  }
  ...on ResultError{
    status_code
    message
  }
}
}
`;

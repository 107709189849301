export const LOGS = `
query logs(
  $filter: FilterLogInput
  $orderBy: LogOrderInput
  $searchText: String
  $skip: Int
  $take: Int
) {
  logs(
    filter: $filter
    orderBy: $orderBy
    searchText: $searchText
    skip: $skip
    take: $take
  ) {
    __typename
    ... on Log {
      id
      observations
      its
      action {
        id
        name
      }
      createdBy {
        id
        username
        person {
          name
          lastname
        }
      }
    }
    ... on ResultError {
      status_code
      message
      message_translation_key
    }
  }
}
`;

export const PROPERTY_STATUS_CUA = `
query propertyStatusCua($filter:  FilterPropertyStatusCua, $orderBy: PropertyStatusCuaOrderInput, $skip: Int, $take: Int){
  propertyStatusCua(filter: $filter, orderBy: $orderBy, skip: $skip, take: $take){
    __typename
    ...on PropertyStatusCua{
      id
      property_attribute_id
      value      
      description
      property_status_documentation{
        documentation_id
        documentation{
          filename
          mimetype
        }
      }
    }
    ...on ResultError{
      status_code
      message
    }
  }
}
`;

export const NOTIFICATIONS = `
query notifications(
  $filter: FilterNotificationInput,
  $searchText: String,
  $orderBy: NotificationOrderInput,
  $skip: Int,
  $take: Int,
) {
  notifications(
    filter: $filter,
    searchText: $searchText,
    orderBy: $orderBy,
    skip: $skip,
    take: $take,
  ) {
    __typename
    ... on Notification {
      id
      recipient_user_id
      recipient_role_id
      title
      description
      notification_type_id
      action_id
      person_id
      invitation {
        id
        email
        uid
      }
      cua_id
      property_id
      complaint_id
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const PROPERTY_ATTRIBUTES = `
query propertyAttributes(
    $filter: PropertyAttributeFilterInput, 
    $orderBy: PropertyAttributeOrderInput, 
    $skip:Int, 
    $take:Int
  ) {
   propertyAttributes(
    filter:$filter, 
    orderBy:$orderBy, 
    skip:$skip, 
    take:$take
  ) {
        __typename
        ...on PropertyAttribute{
          id
        name
        }
      ...on ResultError{
        status_code
        message
      }
      }
  }
`;

export const PAYMENT_FREQUENCIES = `
query paymentFrequencies($filter: PaymentFrequencyFilterInput, $orderBy: PaymentFrequencyOrderInput, $skip: Int, $take: Int){
    paymentFrequencies(filter:$filter, orderBy:$orderBy, skip:$skip, take:$take){
      __typename
      ...on PaymentFrequency{
        id
        name
      }
      ...on ResultError{
        status_code
        message
      }
    }
  }
`;

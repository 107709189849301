export const PERSON_IS_EDITABLE = `
query personIsEditable($person_id: Int!){
    personIsEditable(person_id: $person_id){
      __typename
      ...on ResponseMessage{
        status
        response
      }
      ...on ResultError{
        status_code
        message
      }
    }
  }
`;

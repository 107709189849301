export const DELETE_NOTIFICATION = `
mutation deleteNotification($id: Int!) {
  deleteNotification(id: $id) {
    __typename
    ... on Notification {
      id
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

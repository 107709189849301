import { NavContext } from '@ionic/react';
import { Button, Result } from 'antd';
import { useContext } from 'react';
import { ContextApp } from '../contexts/ContextApp';
import { IRouteConfig } from '../interfaces/routeConfig';

export const useCheckAuthority = (
  props: IRouteConfig | undefined,
): JSX.Element | null => {
  const { functions, t } = useContext(ContextApp);
  const { navigate } = useContext(NavContext);
  if (
    props?.authority &&
    !functions?.find((item) => item === props?.authority)
  ) {
    return (
      <Result
        status="403"
        title="403"
        subTitle={t('message.pageNotAuthorized')}
        extra={
          <Button size="large" type="primary" onClick={() => navigate('/')}>
            {t('action.goToHome')}
          </Button>
        }
      />
    );
  }
  return null;
};

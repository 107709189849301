export const CREATE_USER = `
  mutation createUser(
    $input:  CreateUserInput!
  ) {
    createUser(
      input: $input
    ) {
      __typename
      ... on User {
        email
        id
      }
      ... on ResultError {
        status_code
        message
      }
    }
  }
`;

export const UPDATE_USER = `
mutation updateUser(
  $input: UpdateUserInput!
  $inputProfile: CreateProfileInput
) {
  updateUser(input: $input, inputProfile: $inputProfile) {
    __typename
    ... on User {
      id
      username
      active
      person {
        id
        name
        lastname
        email
      }
      profile_id
      profile {
        phone
        timezone
      }
      profile_picture_id
    }
    ... on ResultError {
      status_code
      message
    }
  }
}

`;

export const DELETE_USER = `
  mutation deleteUser($id: Int!) {
    deleteUser(id: $id) {
      __typename
      ... on User {
        id
      }
      ... on ResultError {
        status_code
        message
      }
    }
  }
`;

export const RESET_PASSWORD = `
  mutation resetPassword(
    $email: String,
  ){
    resetPassword(email:$email){
      __typename
      ...on RecoveryResult{
        result
      }
      ...on ResultError{
        status_code
        message
}
    }
  }
`;

export const SET_PASSWORD = `
  mutation setPassword(
    $token: String!,
    $password: String!,
  )
  {
    setPassword(
        token:$token, 
        password:$password
    ){
      __typename
      ...on RecoveryResult{
        result
      }
      ...on ResultError{
        status_code
        message
}
    }
  }
`;

export const UPSERT_USER_ROLE = `
  mutation upsertUserRole($input: UpsertUserRoleInput!){
  upsertUserRole(input:$input)
  {
    __typename
    ...on UserRole{
      id
      role {
        id
        name
      }
    }
    ...on ResultError {
      status_code
      message
    }
  }
}
`;

export const UPSERT_USER_FIREBASE = `
mutation upsertUserFirebase(
  $input: UpsertUserFirebaseInput!
){
  upsertUserFirebase(
    input:$input    
  ){
    __typename
    ...on User{
            uid
    }
    ...on ResultError{
      status_code
      message
    }    
  }
}
`;

export const CREATE_PROFILE_PICTURE = `
mutation createProfilePicture(
  $file:  Upload!
){
  createProfilePicture(
    file: $file,
  ){
    __typename
    ...on ProfilePicture{
      id
      filename
      mimetype
      encoding
    }
    ...on ResultError{
      status_code
      message
    }
  }
}
`;

export const UPDATE_PROFILE_PICTURE = `
mutation updateProfilePicture($file: Upload!){
  updateProfilePicture(
    file: $file,
  ){
    __typename
    ...on ProfilePicture{
      id
      filename
      mimetype
      encoding
      file
    }
    ...on ResultError{
      status_code
      message
    }
  }
}
`;
export const DELETE_PROFILE_PICTURE = `
mutation deleteProfilePicture($id: Int!){
  deleteProfilePicture(
    id: $id,
  ){
    __typename
    ...on ProfilePicture{
      id
    }
    ...on ResultError{
      status_code
      message
    }
  }
}
`;

export const UPDATE_MY_PASSWORD = `
mutation updateMyPassword($input: UpdatePasswordInput!) {
  updateMyPassword(
    input: $input
  ) {
    __typename
    ... on User {
      id
      username
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const COMPLETE_REGISTER = `
mutation completeRegister($token: String!, $password:String!){
  completeRegister(token:$token, password:$password){
    __typename
    ...on CompleteRegister{
      result
    }
    ...on ResultError{
      status_code
      message
    }
  }
}`;

export const RESEND_VALIDATE_TOKEN = `
mutation resendValidateToken($expired_token:String!){
  resendValidateToken(expired_token:$expired_token){
    __typename
    ...on CompleteRegister{
			result
    }
    ...on ResultError{
      status_code
      message
    }
  }
}
`;

export const CREATE_USER_PUBLIC = `
mutation createUserPublic(
  $input:  CreateUserInput!
  $inputPerson: CreatePersonInput!
) {
  createUserPublic(
    input: $input
    inputPerson: $inputPerson
  ) {
    __typename
    ... on User {
      id
    }
    ... on ResultError {
      status_code
      message
    }
  }
} 
`;

export const CHANGE_LAST_ROLE_ID = `
mutation changeLastRoleId($role_id: Int!){
  changeLastRoleId(role_id:$role_id){
    __typename
    ...on User{
      id
    }
    ...on ResultError{
      status_code
      message
    }
  }
}

`;

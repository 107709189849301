import Service from './graphql/GraphqlService';
import { ContextApp } from '../contexts/ContextApp';
import { useContext } from 'react';

import { EnumsValues } from '../enums/EnumsValues';
import { logoutFirebase } from '../configs/firebase/firebase-config';

export const UserService = () => {
  const { customRequest, Query } = Service();
  const {
    setUser,
    setRoles,
    setFunctions,
    setSessionExpired,
    setUserDataFirebase,
    user,
  } = useContext(ContextApp);

  const authenticate = async () => {
    try {
      const res = await customRequest({
        query: Query.authenticate,
      });
      return res;
    } catch (error) {
      return null;
    }
  };

  const logout = async () => {
    // TODO: falta implementar lista negra en servicio
    // customRequest({
    //   query: Query.logout,
    // })
    try {
      localStorage.removeItem(EnumsValues.LocalStorageKeys.Token);
      localStorage.removeItem(EnumsValues.LocalStorageKeys.TwoFactorValidated);
      localStorage.removeItem(EnumsValues.LocalStorageKeys.UserId);
      if (user?.uid) {
        logoutFirebase();
      }
      setUser(() => null);
      setRoles(() => []);
      setFunctions(() => []);
      setSessionExpired(false);
      setUserDataFirebase(() => null);
    } catch (error) {
      throw error;
    }
  };

  const expireSession = () => {
    try {
      localStorage.removeItem(EnumsValues.LocalStorageKeys.Token);
      setRoles(() => []);
      setFunctions(() => []);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
  return {
    authenticate,
    logout,
    expireSession,
  };
};

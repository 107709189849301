export const CREATE_OPERATION = `mutation createOperation(
  $input: CreateOperationInput!,
){
  createOperation(
    input: $input
  ){
    __typename
    ... on Operation{
      id
      name
      start_date
      end_date
    }
    ... on ResultError{
      status_code
      message
    }
  }
}`;

export const UPDATE_OPERATION = `mutation updateOperation(
	$input: UpdateOperationInput!,
  ) {
    updateOperation(
      input: $input,
    ) {
      __typename
      ... on Operation {
        id
  			name
        start_date
        end_date
      }
      ... on ResultError {
        status_code
        message
      }
    }
  }`;

export const DELETE_OPERATION = `mutation deleteOperation(
  $id: Int!
){
  deleteOperation(
    id: $id
  ){
        __typename
    ... on Operation{
      id
      name
      start_date
      end_date
    }
    ... on ResultError{
      status_code
      message
    }
  }
}`;

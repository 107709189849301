export const CREATE_CUA = `
mutation createCua($input: CreateCuaInput!){
    createCua(input:$input){
      __typename
      ...on Cua{
        id
        
      }
      ...on ResultError{
        status_code
        message
      }
    }
  }
`;

export const CREATE_PROPERTY_STATUS = `
mutation createPropertyStatus($cuaId: Int!,$idsToDelete:  [Int!]!, $input:  [CreatePropertyStatusCua!]!){
  createPropertyStatus(cuaId:$cuaId,idsToDelete:$idsToDelete, input:$input){
    __typename
    ...on ResponseMessage{
      status
      response
    }
    ...on ResultError{
      status_code
      message
    }
  }
}
`;

export const DELETE_CUA = `
mutation deleteCua($cua_id: Int!) {
  deleteCua(cua_id: $cua_id) {
    __typename
    ... on ResponseMessage {
      status
      response
    }

    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const REGISTER_AGREEMENT_DATA = `
mutation registerAgreementData($input: AgreementInput!){
	registerAgreementData(input:$input){
    __typename
    ...on ResponseMessage{
      status
      response
    }
    ...on ResultError{
      status_code
      message
    }
  }
}
`;

export const CREATE_PERSON_CUA = `
mutation createPersonCua($input: PersonCuaCreateInput!){
  createPersonCua(input: $input){
    __typename
    ...on ResponseMessage{
      status
      response
    }
    ...on ResultError{
      status_code
      message
    }
  }
}
`;

export const APPROVE_CUA = `
mutation approveCua($cua_id:Int!){
  approveCua(cua_id:$cua_id){
   __typename
   ...on ResponseMessage{
     status
     response
   }
   ...on ResultError{
     status_code
     message
   }
 }
}
`;

export const REJECT_CUA = `
mutation rejectCua($cua_id: Int!){
  rejectCua(cua_id:$cua_id){
    __typename
    ...on ResponseMessage{
      status
      response
    }
    ...on ResultError{
      status_code
      message      
    }
  }
}
`;

export const LOCATIONS = `
query locations(
    $searchText: String, 
    $filter: FilterLocationInput, 
    $orderBy:  LocationOrderInput, 
    $skip: Int, 
    $take: Int){
      locations(searchText: $searchText, filter: $filter, orderBy: $orderBy, skip: $skip, take: $take){
        __typename
        ...on Location{
          id        
          name
          department{
            province_id
          }
        }
        ...on ResultError{
          status_code
          message
        }
      }
    }
`;

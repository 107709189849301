export const PROPERTY_LIST = `
query propertyList($filter: PropertyFilterInput, $orderBy:  PropertyOrderInput, $skip: Int, $take: Int){
  propertyList( filter: $filter, orderBy: $orderBy, skip: $skip, take: $take){
    __typename
    ...on Property{
      id
      property_type_id
      address
      location_id
      area
      name
      property_type{
        name
      }
      location{
        name
        department{
          province{
            name
          }
        }
      }
    }
    ...on ResultError{
      status_code
      message
    }
  }
}
`;

export const PROPERTY = `
query property($id:  Int!){
  property(id:$id){
    __typename
    ...on Property{
      id
      address
      name
      plant
      status
      postal_code
      location_id
      location{
        name
        department{
          province_id
          province{
            name
          }
        }        
      }
      property_type{
        name
      }
      floor
      department
      registration
      area
      covered_area
      purpose
      property_type_id
      plot_number
      tome
      folio
      person_property{
        id
        cua_role_id
        person{
          id
          name
          lastname
          document
          person_type
          marital_status
        }
      }
      invitation{
        id
        invitation_type{
          id
          name
        }
        email
      }
    }
    ...on ResultError{
      status_code
      message
    }
  }
}
`;

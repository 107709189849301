import { useContext } from 'react';
import { Mutation } from './mutation/index';
import { Query } from './query/index';
import { Tools } from '../../shared';
import axios, { AxiosRequestConfig } from 'axios';
import { BackendError } from '../../shared/BackendError';
import { ContextApp } from '../../contexts/ContextApp';
import { EnumsValues, ObjectStore } from '../../enums/EnumsValues';
import ms from 'ms';
import {
  IOfflineData,
  useOfflineFunctions,
} from '../../shared/useOfflineFunctions';

const backendWSUrl: any = process.env.REACT_APP_BACKEND_WS;
console.warn('backendWSUrl');
console.warn(backendWSUrl);
const rmTypenameObj = (obj: any) => {
  let values: any = '{}';
  values = JSON.parse(values);
  Object.keys(obj).forEach((key) => {
    if (key !== '__typename') {
      values[key] = obj[key];
    }
  });
  return values;
};

const parseObj = (obj: any) => {
  const result: any = {};
  Object.keys(obj).forEach((key: any) => {
    const nan: any = Number(obj[key]);
    if (!(nan.toString() === 'NaN')) {
      result[key] = Number(obj[key]);
    } else if (obj[key] === 'false') {
      result[key] = false;
    } else if (obj[key] === 'true') {
      result[key] = true;
    } else {
      result[key] = obj[key];
    }
  });
  return result;
};

const resultQuery = async (data: any): Promise<any | any[]> => {
  return new Promise((resolve, reject) => {
    const result = async (
      responseQuery: any,
      success: Function,
      error: Function,
    ) => {
      try {
        const response: any | any[] = responseQuery;

        if (Array.isArray(response) && !response.length) {
          success(response);
        } else if (!response) {
          success(response);
        } else {
          const typename: any =
            Array.isArray(response) && response.length
              ? response[0]?.__typename
              : response?.__typename;
          if (!typename) {
            error({
              status_code: null,
              message: 'Error: data incorrecta pasado a "resultQuery".',
            });
          } else if (typename && typename === 'ResultError') {
            if (Array.isArray(response) && response.length) {
              error({
                status_code: response[0].status_code,
                message: response[0].message,
              });
            } else {
              error({
                status_code: response.status_code,
                message: response.message,
              });
            }
          } else {
            success(response);
          }
        }
      } catch (err: any) {
        error({
          status: false,
          status_code: null,
          message: err.message,
        });
      }
    };
    result(
      data,
      (success: any) => {
        resolve(success);
      },
      (err: BackendError) => {
        reject(err);
      },
    );
  });
};
/**
 * @description Lista de códigos de error
 */
export const ERROR_CODE = {
  SERVER_ERROR: 'SERVER_ERROR',
  AUTHORIZATION_ERROR: 'AUTHORIZATION_ERROR',
  AUTHENTICATION_ERROR: 'AUTHENTICATION_ERROR',
  GENERAL_ERROR: 'GENERAL_ERROR',
};

const GraphqlService = () => {
  const { setLoading, setUser, setSessionExpired, user } =
    useContext(ContextApp);
  const { canUseOfflineFunctions, indexedDatabaseRef } = useOfflineFunctions();

  /**
   * @description Lista de códigos de error
   */
  const resolveResultError = (
    resultError: BackendError,
    callback: Function,
  ) => {
    if (resultError && resultError.status_code) {
      switch (resultError.status_code) {
        case ERROR_CODE.SERVER_ERROR:
          // @TODO: loguer
          callback();
          break;
        case ERROR_CODE.AUTHENTICATION_ERROR:
          Tools.messageModalInfo(resultError.message);
          setUser(undefined);
          break;
        case ERROR_CODE.AUTHORIZATION_ERROR:
          callback();
          break;
        case ERROR_CODE.GENERAL_ERROR:
          // @TODO: loguer
          callback();
          break;
        default:
          // @TODO: loguer
          callback();
          break;
      }
    } else {
      // @TODO: loguer
      callback();
    }
  };
  const customFileRequest = async (
    options: Omit<IRequestOptions, 'query'> & { mutation: IRequest },
    fileData: IFileData[],
  ) => {
    /* Armamos el form data */
    const formData = new FormData();
    const operations = JSON.stringify({
      operationName: options.mutation.name,
      query: options.mutation.gql,
      variables: options.variables,
    });
    formData.append('operations', operations);
    const map: any = {};
    fileData.forEach((file, index) => {
      map[(index + 1).toString()] = [file.path];
    });

    formData.append('map', JSON.stringify(map));

    fileData.forEach((file, index) => {
      formData.append((index + 1).toString(), file.file || 'null');
    });

    /* Armamos el objeto config para axios */
    const axiosConfig: AxiosRequestConfig = {};
    let token = localStorage.getItem(EnumsValues.LocalStorageKeys.Token);
    if (token) {
      axiosConfig.headers = {
        Authorization: `Bearer ${token}`,
      };
    }
    const loading =
      options.loading !== undefined && options.loading === false ? false : true;
    if (loading) {
      setLoading(() => true);
    }
    try {
      const res = await axios.post(backendWSUrl, formData, axiosConfig);
      try {
        return await resultQuery(res.data.data[options.mutation.name]);
      } catch (error) {
        resolveResultError(error as BackendError, () => {
          if (loading) {
            setLoading(() => false);
          }
          throw error;
        });
      }
    } catch (err: unknown) {
      if (loading) {
        setLoading(() => false);
      }
      throw err;
    }
    if (loading) {
      setLoading(() => false);
    }
  };

  const transformVariablesToIDBKey = (variables: any): string => {
    let data;
    if (!variables) {
      data = {};
    } else {
      data = JSON.parse(JSON.stringify(variables));
    }

    if (user) {
      data.user_id = user.id;
    }

    return JSON.stringify(data);
  };

  const getDataFromIndexedDB = async ({
    objectStore,
    offlineDataTTL = ms('7d'),
    variables,
  }: {
    objectStore: ObjectStore;
    offlineDataTTL?: number;
    variables: any;
  }) => {
    if (!canUseOfflineFunctions || !indexedDatabaseRef.current) {
      console.error(
        'Base de datos no inicializada, pongase en contacto con el administrador del sistema',
      );
      return undefined;
    }

    const now = new Date();

    const db = indexedDatabaseRef.current;

    const key = transformVariablesToIDBKey(variables);

    const objectStoreData: IOfflineData | undefined = await db.get(
      objectStore,
      key,
    );

    if (
      !objectStoreData ||
      now.getTime() > objectStoreData.timestamp.getTime() + offlineDataTTL
    ) {
      return undefined;
    }

    return objectStoreData;
  };

  const updateDataInIndexedDB = async ({
    objectStore,
    data,
    variables,
  }: {
    objectStore: ObjectStore;
    data: any;
    variables: any;
  }) => {
    if (!canUseOfflineFunctions || !indexedDatabaseRef.current) {
      console.error(
        'Base de datos no inicializada, pongase en contacto con el administrador del sistema',
      );
      return;
    }

    const db = indexedDatabaseRef.current;
    const key = transformVariablesToIDBKey(variables);

    const dataToInsert: IOfflineData = {
      data,
      key,
      timestamp: new Date(),
    };

    await db.put(objectStore, dataToInsert, key);
  };

  const customRequest = async (
    options: IRequestOptions,
    isRetry?: boolean,
  ): Promise<any | any[] | BackendError> => {
    const req = {
      type: '',
      name: '',
    };
    const call: any = {};

    if (options.variables) {
      call.variables = options.variables;
    }

    let offlineData: IOfflineData | undefined;

    if (options && options.query) {
      if (!options.cache) {
        call.fetchPolicy = 'no-cache';
      }
      req.type = 'query';
      req.name = options.query.name;
      call.query = options.query.gql;

      if (options.query.objectStore) {
        /* Logica para consultar offline */
        try {
          offlineData = await getDataFromIndexedDB({
            objectStore: options.query.objectStore,
            variables: options.variables,
            offlineDataTTL: options.query.offlineDataTTL,
          });
        } catch (error) {
          console.error(error);
        }

        if (offlineData && !navigator.onLine) {
          return await resultQuery(offlineData.data);
        }
      }
    } else if (options && options.mutation) {
      req.type = 'mutate';
      req.name = options.mutation.name;
      call.mutation = options.mutation.gql;
    } else {
      throw new Error('Error customRequest: query and mutation is null.');
    }

    const loading =
      options.loading !== undefined && options.loading === false ? false : true;

    if (loading) {
      setLoading(() => true);
    }
    const axiosConfig: AxiosRequestConfig = {};
    let localToken = localStorage.getItem(EnumsValues.LocalStorageKeys.Token);
    if (localToken) {
      axiosConfig.headers = {
        Authorization: `Bearer ${localToken}`,
      };
    }
    axiosConfig.withCredentials = true;
    try {
      const res = await axios.post(
        backendWSUrl,
        {
          variables: options.variables || {},
          operationName: req.name,
          query: options.query?.gql || options.mutation?.gql,
        },
        axiosConfig,
      );

      if (res.data.data) {
        try {
          const data = res.data.data[req.name];
          if (options.query?.objectStore) {
            updateDataInIndexedDB({
              objectStore: options.query.objectStore,
              data,
              variables: options.variables,
            });
          }
          return await resultQuery(data);
        } catch (err) {
          if (loading) {
            setLoading(() => true);
          }
          resolveResultError(err as BackendError, () => {
            if (loading) {
              setLoading(() => false);
            }
            throw err;
          });
        }
        if (loading) {
          setLoading(() => false);
        }
      } else if (res.data.errors) {
        if (res.data.errors[0].status_code === 403) {
          throw new BackendError(
            res.data.errors[0].status_code,
            res.data.errors[0].message,
          );
        } else if (res.data.errors[0].status_code === 401) {
          if (!isRetry) {
            const data = await customRequest(
              {
                mutation: Mutation.refreshToken,
              },
              true,
            );
            localStorage.setItem(
              EnumsValues.LocalStorageKeys.Token,
              data.accessToken,
            );
            return await customRequest(options, true);
          } else {
            setSessionExpired(true);
          }
        } else {
          throw new BackendError(
            res.data.errors[0]?.status_code,
            res.data.errors[0]?.message,
          );
        }
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        /* Detectamos connection aborted */
        if (error.code === 'ECONNABORTED' && offlineData) {
          return await resultQuery(offlineData.data);
        }
        console.log(error);
      }

      if (loading) {
        setLoading(() => false);
      }
      throw error;
    }
  };
  return {
    Query,
    Mutation,
    rmTypenameObj,
    parseObj,
    customRequest,
    customFileRequest,
  };
};

export default GraphqlService;

export interface IRequest {
  name: string;
  gql: any;
}

export interface IQuery extends IRequest {
  objectStore?: ObjectStore;
  offlineDataTTL?: number;
}

export interface IRequestOptions {
  query?: IQuery;
  mutation?: IRequest;
  variables?:
    | {
        input?: object;
        orderBy?: object;
        skip?: number;
        take?: number;
      }
    | any;
  cache?: boolean;
  loading?: boolean;
}

export interface IFileData {
  file: File | null;
  path: string;
}

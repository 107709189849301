export const INVITATIONS = `
query invitations($filter:  InvitationFilterInput, $orderBy:  InvitationOrderInput, $skip: Int, $take: Int){
    invitations(filter: $filter, orderBy: $orderBy, skip:$skip, take:$take){
      __typename
      ...on Invitation{
        id
        person{
          name
            lastname
            document
            person_type
        }
      }
      ...on ResultError{
        status_code
        message
      }
    }
  }
`;

export const CHECK_INVITATION = `
query checkInvitation($invitation_uid: String!){
  checkInvitation(invitation_uid:$invitation_uid){
    __typename
    ...on Invitation{
      id
      uid
      createdBy{
        person{
          id
          name
          lastname
          email
        }
      }
      invitation_type_id
      invitation_type{
        id
        name
      }
      cua{
        id
        person_cua{
          cua_role_id
          person{
            name
            lastname
          }
        }
        property{
          address
          location{
            name
            department{
              province{
                name
              }
            }
          }
        }
      }
      property{
        address
        location{
          name
          department{
            province{
              name
            }
          }
        }
      }
    }
    ...on ResultError{
      status_code
      message
    }
  }
}
`;

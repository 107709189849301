/* eslint-disable no-fallthrough */
import { openDB } from 'idb';
import { ObjectStore } from '../enums/EnumsValues';

export const openDatabase = async () => {
  const latestVersion = 3; // Tiene que estar siempre un numero adelante del ultimo case del switch debajo
  const db = await openDB('proyecto-base', latestVersion, {
    upgrade(db, oldversion) {
      let lastVersionApplied = oldversion;
      /* Cada vez que hagamos un cambio a la estructura de la base de datos offline, tenemos que actualizar este switch y la variable latestVersion */
      switch (lastVersionApplied) {
        // @ts-ignore
        case 0:
          // Creamos el store de companies
          db.createObjectStore(ObjectStore.Company);
          lastVersionApplied++;
        // @ts-ignore
        case 1:
          // Creamos store de acciones diferidas
          db.createObjectStore(ObjectStore.PostponedActions, {
            autoIncrement: true,
          });
          lastVersionApplied++;
        case 2:
          db.createObjectStore(ObjectStore.Operation);
          lastVersionApplied++;
      }
    },
  });

  return db;
};

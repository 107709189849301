import ReactDOM from 'react-dom/client';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { Suspense } from 'react';
import { PageLoading } from '@ant-design/pro-layout';
import { WebSocketLink } from '@apollo/client/link/ws';
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { SubscriptionClient } from 'subscriptions-transport-ws';

// Inicialización de cliente Apollo para subscripciones en graphql
const wsLink = process.env.REACT_APP_BACKEND_WEBSOCKET
  ? new WebSocketLink(
      new SubscriptionClient(process.env.REACT_APP_BACKEND_WEBSOCKET),
    )
  : undefined;

const client = new ApolloClient({
  link: wsLink,
  cache: new InMemoryCache(),
});

const rootCreated = document.getElementById('root');

if (rootCreated) {
  const root = ReactDOM.createRoot(rootCreated);
  root.render(
    <ApolloProvider client={client}>
      <Suspense fallback={<PageLoading />}>
        <App />
      </Suspense>
    </ApolloProvider>,
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA

// Si se quiere que la pagina recargue sola cuando la cache se actualiza, cambiar el llamado a unregister por:

serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    if (registration.waiting) {
      registration.waiting.postMessage({ type: 'SKIP_WAITING' });
      registration.waiting.addEventListener('statechange', () => {
        window.location.reload();
      });
    }
  },
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

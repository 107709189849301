export const OPERATIONS = `query operations(
  $filter:  FilterOperationInput,
  $orderBy:  OperationOrderInput,
  $searchText : String,
  $skip: Int,
  $take: Int,
){
operations(
filter: $filter
orderBy: $orderBy
searchText : $searchText,
skip: $skip
take: $take
)
{
  __typename
  ... on Operation{
    id
    name
    start_date
    end_date
    tags
  }
  ... on ResultError{
      status_code
      message
  }
}
}`;

export const EXPORT_OPERATIONS = `
query exportOperations(
  $searchText: String
  $filter: FilterOperationInput
  $orderBy: OperationOrderInput
  $format: ExportFormat!
) {
  exportOperations(
    searchText: $searchText
    filter: $filter
    orderBy: $orderBy
    format: $format
  ) {
    __typename
    ... on ExportResult {
      file
      mimetype
      encoding
    }
    ... on ResultError {
      status_code
      message
      message_translation_key
    }
  }
}
`;

export const CREATE_TAG = `
mutation createTag(
    $input: CreateTagInput!,
  ){
    createTag(
      input: $input
    ){
      __typename
      ... on Tag{
        id
        name
      }
      ... on ResultError{
        status_code
        message
      }
    }
  }
`;

export const PROPERTY_TYPES = `
query propertyTypes( $filter: FilterPropertyTypeInput, $orderBy:  PropertyTypeOrderInput, $skip: Int, $take: Int){
    propertyTypes(filter: $filter, orderBy: $orderBy, skip: $skip, take: $take){
      __typename
      ...on PropertyType{
        id
        name
      }
      ...on ResultError{
        status_code
        message
      }
    }
  }
`;

export const CREATE_COMPANY = `
mutation createCompany(
    $input: CreateCompanyInput!,
  ){
    createCompany(
      input: $input
    ){
      __typename
      ... on Company{
        id
        name
        description
      }
      ... on ResultError{
        status_code
        message
      }
    }
  }
`

export const UPDATE_COMPANY = `
mutation updateCompany(
    $input:  UpdateCompanyInput!,
  ){
    updateCompany(
      input: $input
    ){
      __typename
      ... on Company{
        id
        name
        description
      }
      ... on ResultError{
        status_code
        message
      }
    }
  }
`

export const DELETE_COMPANY = `
mutation deleteCompany(
    $id: Int!
  ){
    deleteCompany(
      id: $id
    ){
          __typename
      ... on Company{
        id
        name
        description
      }
      ... on ResultError{
        status_code
        message
      }
    }
  }
`
import { PageLoading } from '@ant-design/pro-layout';
import { Button, Divider, Drawer, Form, Radio, Space } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { ContextApp } from '../../../contexts/ContextApp';
import { EnumsValues } from '../../../enums/EnumsValues';

import {
  DEFAULT_ORDER_BY_PROPERTY_TYPES,
  IPropertyType,
} from '../../../interfaces/PropertyType';
import GraphqlService from '../../../services/graphql/GraphqlService';

interface IFilterProperties {
  open: boolean;
  onClose: () => void;
  onClickCleanFilter: () => void;
  onFinishForm: (values: any) => void;
}

export const FilterProperties = (props: IFilterProperties) => {
  const { open, onClose, onFinishForm, onClickCleanFilter } = props;
  // Contexts
  const { t } = useContext(ContextApp);
  // States
  const [loadingPropertyTypes, setLoadingPropertyTypes] = useState(true);
  const [propertyTypes, setPropertyTypes] = useState<IPropertyType[]>([]);
  // Utilities
  const [form] = useForm();
  const { Query, customRequest } = GraphqlService();
  const dataFetchedRef = useRef<boolean>(false);
  // Functions
  const getPropertyTypes = useCallback(async () => {
    setLoadingPropertyTypes(true);
    try {
      const response: IPropertyType[] = await customRequest({
        query: Query.propertyTypes,
        variables: {
          orderBy: { ...DEFAULT_ORDER_BY_PROPERTY_TYPES },
        },
      });
      setPropertyTypes(response);
    } catch (error) {}
    setLoadingPropertyTypes(false);
  }, []);

  // useEffects
  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    getPropertyTypes();
  }, []);
  return (
    <Drawer
      placement={'right'}
      closable={false}
      onClose={() => onClose()}
      open={open}
      width={270}
    >
      <div className="container-drawer-content">
        <div className="container-drawer-content__header">
          <span className="container-drawer-content__header__text">
            {t('action.filter')}
          </span>
          <Button
            className="container-drawer-content__header__button-clean-filter"
            type="link"
            onClick={() => {
              onClickCleanFilter();
              form.resetFields();
            }}
          >
            {t('action.cleanFilter')}
          </Button>
        </div>
        <Divider />

        <div className="container-drawer-content__filters">
          {loadingPropertyTypes ? (
            <PageLoading />
          ) : (
            <>
              <Form
                className="container-drawer-content__filters__form"
                layout="vertical"
                form={form}
                onFinish={(values) => {
                  onFinishForm(values);
                }}
              >
                <div className="container-drawer-content__filters__form__content-form">
                  {propertyTypes.length && (
                    <>
                      <Form.Item
                        name={'property_type_id'}
                        label={t('entity.type')}
                      >
                        <Radio.Group>
                          <Space direction="vertical">
                            {propertyTypes.map((propertyType) => (
                              <>
                                <Radio value={propertyType.id}>
                                  {propertyType.name}
                                </Radio>
                              </>
                            ))}
                          </Space>
                        </Radio.Group>
                      </Form.Item>
                    </>
                  )}
                  <Form.Item
                    name={'availability'}
                    label={t('entity.availability')}
                  >
                    <Radio.Group>
                      <Space direction="vertical">
                        <Radio
                          value={EnumsValues.PropertyOcupationalStatus.Free}
                        >
                          {t('entity.propertyFree')}
                        </Radio>
                        <Radio
                          value={EnumsValues.PropertyOcupationalStatus.Busy}
                        >
                          {t('entity.propertyBusy')}
                        </Radio>
                      </Space>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item name={'cua_role_id'} label={t('entity.property')}>
                    <Radio.Group>
                      <Space direction="vertical">
                        <Radio value={EnumsValues.PropertyRoleOwner.Owner}>
                          {t('entity.ownProperty')}
                        </Radio>
                        <Radio value={EnumsValues.PropertyRoleOwner.Manager}>
                          {t('entity.manageProperty')}
                        </Radio>
                      </Space>
                    </Radio.Group>
                  </Form.Item>
                </div>
                <Button
                  className="container-drawer-content__filters__form__button-submit"
                  type="primary"
                  size="large"
                  htmlType="submit"
                >
                  {t('action.apply')}
                </Button>
              </Form>
            </>
          )}
        </div>
      </div>
    </Drawer>
  );
};

export const GET_DOCUMENTATION_FILE = `
query getDocumentationFile($id: Int!, $cua_id: Int){
    getDocumentationFile(id:$id, cua_id: $cua_id){
      __typename
      ...on Documentation{
        id
        filename
        mimetype
        encoding
        file
      }
      ...on ResultError{
        status_code
        message
      }
    }
  }
`;

export const GET_DOCUMENTATION_LINK = `
query getDocumentationLink($id: Int!, $cua_id: Int){
  getDocumentationLink(id: $id, cua_id: $cua_id){
		__typename
    ...on ResponseMessage{
      status
      response
    }
    ...on ResultError{
      status_code
      message
    }
  }
}
`;

import './CardNavigation.less';

interface ICardNavigationProps {
  icon: JSX.Element;
  title: string;
  description: string;
  message?: string | JSX.Element;
  onClick?: () => void;
}

const CardNavigation = (props: ICardNavigationProps) => {
  const { icon, title, description, message, onClick } = props;
  return (
    <div className="card-navigation" onClick={() => onClick && onClick()}>
      <div className="card-navigation__container">
        <div className="card-navigation__container__container-icon">{icon}</div>
        <div className="card-navigation__container__container-text">
          <h6 className="card-navigation__container__container-text__title">
            {title}
          </h6>
          <span className="card-navigation__container__container-text__description">
            {description}
          </span>
          {message && (
            <span className="card-navigation__container__container-text__message">
              {message}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};
export default CardNavigation;

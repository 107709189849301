export const CREATE_PROPERTY = `
mutation createProperty($data: CreatePropertyInput!){
    createProperty(data: $data){
      __typename
      ...on ResponseMessage{
        status
        response
      }
      ...on ResultError{
        status_code
        message
      }
    }
  }
`;

export const UPDATE_PROPERTY = `
mutation updateProperty($data: UpdatePropertyInput!){
  updateProperty(data: $data){
    __typename
    ...on Property{
      id      
    }
    ...on ResultError{
      status_code
      message
    }
  }
}
`;

export const DELETE_PROPERTY = `
mutation deleteProperty($property_id: Int!){
  deleteProperty(property_id:$property_id){
    __typename
    ...on ResponseMessage{
      status
      response
    }
    ...on ResultError{
      status_code
      message
    }
  }
}
`;

export const DELETE_PROPERTY_MANAGER = `
mutation deletePropertyManager(
  $person_id:  Int!,
  $property_id:  Int!
){
deletePropertyManager(
  person_id: $person_id,
  property_id: $property_id
){
  __typename
  ...on ResponseMessage{
    status
    response
  }
  ...on ResultError{
    status_code
    message
  }
}
}

`;

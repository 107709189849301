export const CREATE_INVITATION = `
mutation createInvitation($input:CreateInvitationInput!){
    createInvitation(input:$input){
      __typename
      ...on Invitation{
        id      
      }
      ...on ResultError{
        status_code
        message
      }
    }
  }
`;

export const DELETE_INVITATION = `
mutation deleteInvitation($id: Int!){
  deleteInvitation(id:$id){
    __typename
    ...on ResponseMessage{
      status
      response
    }
    ...on ResultError{
      status_code
      message
    }
  }
}
`;

export const ACCEPT_INVITATION = `
mutation acceptInvitation($invitationUid: String!){
  acceptInvitation(invitationUid:$invitationUid){
    __typename
    ...on ResponseMessage{
      status
      response
    }
    ...on ResultError{
      status_code
      message
    }
  }
}
`;

export const REJECT_INVITATION = `
mutation rejectInvitation($invitationUid: String!){
  rejectInvitation(invitationUid:$invitationUid){
    __typename
    ...on ResponseMessage{
      status
      response
    }
    ...on ResultError{
      status_code
      message
    }
  }
}
`;

export const SEND_CUA_INVITATION_TO_TENANT = `
mutation sendCuaInvitationToTenant($input: TenantInvitationInput!){
  sendCuaInvitationToTenant(input: $input){
    __typename
    ...on ResponseMessage{
      status
      response
    }
    ...on ResultError{
      status_code
      message
    }
  }
}

`;

export const TENANT_ACCEPT_CUA = `
mutation tenantAcceptCua($cua_id:  Int!, $person_id:  Int!){
  tenantAcceptCua(cua_id: $cua_id, person_id: $person_id){
    __typename
    ...on ResponseMessage{
      status
			response
    }
    ...on ResultError{
      status_code
      message
    }
  }
}
`;

export const TENANT_REJECT_CUA = `
mutation tenantRejectsCua($cua_id: Int!){
  tenantRejectsCua(cua_id: $cua_id){
    __typename
    ...on ResponseMessage{
      status
     	 response
    }
    ...on ResultError{
      status_code
      message
    }
  }
}
`;

export const CUA = `
query cua($cua_id: Int!){
  cua(cua_id: $cua_id){
    __typename
    ...on Cua{
      id
      cua_status_id
      property_id      
      property_type_id      
      payment_frequency_id
      agreement_type_id
      agreement_end_date
      agreement_start_date
      documentation_id
      documentation{
        filename
        mimetype
      }
      area
      covered_area
      purpose
      property_type{
        name
      }
      invitation{
        id
        role_id
        invitation_type_id
        invitation_type{
          id
          name
        }
        email
      }
      property{
        id
        address
        name
        location{
          name
          department{
            province{
              name
            }
          }
        }
        covered_area
        tome
        folio
        registration
        plant
        purpose
        plot_number
        person_property{
          cua_role_id
          person{
            id
            name
            lastname
            person_type
            document
            marital_status
            start_date
            document_front_id
            document_back_id
            document_type
          }
        }
      }
      cua_action{
        read_cua
        read_history
        can_action
        edit_property
        edit_property_owner
        edit_property_status
        edit_agreement
        edit_tenant
        make_payment
        edit_tenant_invitation
        approve_cua
        reject_cua
        delete_cua
        rescind_cua
        report_cua
        send_invitation
        activate_cua
      }
      cua_status{
        cua_in_process
      }
    }
    ...on ResultError{
      status_code
      message
    }
  }
}
`;
export const CUA_STATUS_SUMMARY = `
query cuaStatusSummary($cua_id: Int!){
  cuaStatusSummary(cua_id: $cua_id){
    __typename
    ...on CuaStatusSummary{
      property
      property_status
      owner
      tenant
      agreement
      invitation
    }
  }
}
`;

export const GET_PERSON_INFO_BY_CUA = `
query getPersonInfoByCua($input: PersonInfoInput!){
  getPersonInfoByCua(input:$input){
    __typename
    ...on Person{
      id
      name
      lastname
      person_type
      document
      marital_status
      document_type
      address
      contact_phone
      email
      document_front_id
      document_back_id
      start_date
      location{
        name
        department{
          province{
            name
          }
        }
      }
    }
    ...on ResultError{
      status_code
      message
    }
  }
}
`;

export const CUAS = `
query cuas($filter:  CuaFilterInput, $orderBy:  CuaOrderInput, $skip:  Int, $take:  Int){
  cuas(filter: $filter, orderBy: $orderBy, skip: $skip, take: $take){
    __typename
    ...on Cua{
      id
      property_type{
      	id
        name
      }
      property{
        id
        address
      }
      cua_status{
        id
        name        
        description_owner
        description_tenant
        cua_in_process
        allow_compliant
      }
      
      cua_action{
        read_cua
        read_history
        can_action
        edit_property
        edit_property_owner
        edit_property_status
        edit_agreement
        edit_tenant
        make_payment
        edit_tenant_invitation
        approve_cua
        reject_cua
        delete_cua
        rescind_cua
        report_cua
        send_invitation
        activate_cua
      }
      next_status{
        id
        name
        description
        description_owner
        description_tenant
      }
    }
    ...on ResultError{
      status_code
      message
    }
  }
}
`;

export const CUA_STATUS_AND_ACTIONS = `
query cua($cua_id: Int!){
  cua(cua_id: $cua_id){
    __typename
    ...on Cua{
      cua_status{
        cua_in_process
      }      
      cua_action{
        read_cua
        read_history
        can_action
        edit_property
        edit_property_owner
        edit_property_status
        edit_agreement
        edit_tenant
        make_payment
        edit_tenant_invitation
        approve_cua
        reject_cua
        delete_cua
        rescind_cua
        report_cua
        send_invitation
        activate_cua
      }
    }
    ...on ResultError{
      status_code
      message
    }
  }
}
`;

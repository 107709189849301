import DefaultRedirectPage from '../pages/DefaultRedirect/DefaultRedirectPage';
import { EnumsValues } from '../enums/EnumsValues';
import { IRouteConfig } from '../interfaces/routeConfig';
import { Suspense, lazy } from 'react';
import { PageLoading } from '@ant-design/pro-layout';
import EmptyLayout from '../layouts/emptyLayout';
import MyPropertiesPage from '../pages/MyProperties/MyPropertiesPage';
import { Redirect } from 'react-router';

const HomePage = lazy(() => import('../pages/Home/HomePage'));
const LoginPage = lazy(() => import('../pages/Login/LoginPage'));
const PublicLayout = lazy(() => import('../layouts/publicLayout'));
const RegisteredLayout = lazy(() => import('../layouts/registered'));
const AppSettingPage = lazy(() => import('../pages/AppSetting/AppSettingPage'));
const ResetPasswordContentPage = lazy(
  () => import('../pages/ResetPassword/ResetPasswordContentPage'),
);
const SetPasswordContentPage = lazy(
  () => import('../pages/SetPassword/SetPasswordPage'),
);
const RolePage = lazy(() => import('../pages/Role/RolePage'));
const PermissionPage = lazy(() => import('../pages/Permission/PermissionPage'));
const UserPage = lazy(() => import('../pages/User/UserPage'));
const HelpPage = lazy(() => import('../pages/Help/HelpPage'));
const Reminder2FA = lazy(
  () => import('../pages/Totp2FA/Reminder2FA/Reminder2FA'),
);
const Generate2FA = lazy(
  () => import('../pages/Totp2FA/Generate2FA/Generate2FA'),
);
const Validate2FA = lazy(
  () => import('../pages/Totp2FA/Validate2FA/Validate2FA'),
);
const Config2FA = lazy(() => import('../pages/Totp2FA/Config2FA/Config2FA'));
const CompleteRegisterPage = lazy(
  () => import('../pages/CompleteRegister/CompleteRegisterPage'),
);
const RegisterPage = lazy(() => import('../pages/Register/RegisterPage'));
const TermsAndConditionsPage = lazy(
  () => import('../pages/TermsAndConditions/TermsAndConditionsPage'),
);
const PersonalDataPage = lazy(
  () => import('../pages/PersonalData/PersonalDataPage'),
);
const OnboardingPage = lazy(() => import('../pages/Onboarding/OnboardingPage'));
const CuasInboxPage = lazy(() => import('../pages/CuasInbox/CuasInboxPage'));
const FormPropertyPage = lazy(
  () => import('../pages/FormProperty/FormPropertyPage'),
);
const PropertyDetailPage = lazy(
  () => import('../pages/PropertyDetail/PropertyDetailPage'),
);
const InviteManagerPage = lazy(
  () => import('../pages/InviteManager/InviteManagerPage'),
);
const InvitationPage = lazy(() => import('../pages/Invitation/InvitationPage'));
const CuaAdministrationPage = lazy(
  () => import('../pages/CuaAdministration/CuaAdministrationPage'),
);
const DetailedReportsPage = lazy(
  () => import('../pages/DetailedReports/DetailedReports'),
);
const CuaLogPage = lazy(() => import('../pages/Log/CuaLogPage'));
const ComplaintsPage = lazy(() => import('../pages/Complaints/ComplaintsPage'));
const ManageComplaintPage = lazy(
  () => import('../pages/ManageComplaint/ManageComplaintPage'),
);
const ComplaintDetailPage = lazy(
  () => import('../pages/ComplaintDetail/ComplaintDetailPage'),
);
const ComplaintRedirectPage = lazy(
  () => import('../pages/ComplaintRedirect/ComplaintRedirectPage'),
);
const CuaRedirectPage = lazy(
  () => import('../pages/CuaRedirect/CuaRedirectPage'),
);

const redirectIfNotExist = {
  path: '*',
  component: () => <Redirect to="/" />,
  key: 'redirectIfNotExiste',
  hideInMenu: true,
};

const routes: IRouteConfig[] = [
  {
    path: '/',
    exact: true,
    component: () => <DefaultRedirectPage />,
    key: 'defaultRedirect',
  },
  {
    path: '/termsandconditions',
    component: () => (
      <Suspense fallback={PageLoading}>
        <TermsAndConditionsPage mode="public" />
      </Suspense>
    ),
    name: 'Términos y condiciones',
    key: 'termsAndConditions',
  },
  {
    path: '/invitation',
    component: () => (
      <Suspense fallback={PageLoading}>
        <>
          <InvitationPage />
        </>
      </Suspense>
    ),
    name: 'Invitación',
    key: 'invitation',
  },
  {
    path: '/complaintRedirect/:complaint_id',
    component: () => (
      <Suspense fallback={PageLoading}>
        <>
          <ComplaintRedirectPage />
        </>
      </Suspense>
    ),
    name: 'Redirección denuncia',
    key: 'complaintRedirect',
  },
  {
    path: '/cuaRedirect/:cua_id',
    component: () => (
      <Suspense fallback={PageLoading}>
        <>
          <CuaRedirectPage />
        </>
      </Suspense>
    ),
    name: 'Redirección cua',
    key: 'cuaRedirect',
  },
  {
    path: '/app',
    component: (props: any) => (
      <Suspense fallback={PageLoading}>
        <RegisteredLayout {...props} />
      </Suspense>
    ),
    key: 'app',
    routes: [
      {
        path: '/app/home',
        component: () => (
          <Suspense fallback={PageLoading}>
            <HomePage />
          </Suspense>
        ),
        name: 'Inicio',
        key: 'home',
      },
      {
        path: '/app/personalData',
        component: () => (
          <Suspense fallback={PageLoading}>
            <PersonalDataPage />
          </Suspense>
        ),
        name: 'Mis datos personales',
        key: 'personalData',
        authority: EnumsValues.Functions.PersonRead,
      },
      {
        path: '/app/myProperties',
        component: () => (
          <Suspense fallback={PageLoading}>
            <MyPropertiesPage />
          </Suspense>
        ),
        name: 'Mis propiedades',
        key: 'myProperties',
        authority: EnumsValues.Functions.IsOwner,
      },
      {
        path: '/app/createProperty',
        component: () => (
          <Suspense fallback={PageLoading}>
            <FormPropertyPage />
          </Suspense>
        ),
        name: 'Nueva propiedad',
        key: 'createProperty',
        hideInMenu: true,
        hideHeader: true,
        authority: EnumsValues.Functions.IsOwner,
      },
      {
        path: '/app/property/:id',
        component: () => (
          <Suspense fallback={PageLoading}>
            <PropertyDetailPage />
          </Suspense>
        ),
        name: 'Detalle de propiedad',
        key: 'property',
        hideInMenu: true,
        hideHeader: true,
        authority: EnumsValues.Functions.IsOwner,
      },
      {
        path: '/app/editProperty/:id',
        component: () => (
          <Suspense fallback={PageLoading}>
            <FormPropertyPage editMode={true} />
          </Suspense>
        ),
        name: 'Datos de la propiedad',
        key: 'editProperty',
        hideInMenu: true,
        hideHeader: true,
        authority: EnumsValues.Functions.IsOwner,
      },
      {
        path: '/app/inviteManager/:id',
        component: () => (
          <Suspense fallback={PageLoading}>
            <InviteManagerPage />
          </Suspense>
        ),
        name: 'Invitar gestor',
        key: 'editProperty',
        hideInMenu: true,
        hideHeader: true,
        authority: EnumsValues.Functions.IsOwner,
      },
      {
        path: '/app/config2fa',
        component: () => (
          <Suspense fallback={PageLoading}>
            <Config2FA />
          </Suspense>
        ),
        hideInMenu: true,
        name: 'Autenticación 2FA',
        key: 'config2fa',
      },
      {
        path: '/app/onboarding',
        component: () => (
          <Suspense fallback={PageLoading}>
            <OnboardingPage />
          </Suspense>
        ),
        hideInMenu: true,
        hideHeader: true,
        name: 'Onboarding',
        key: 'onboarding',
      },
      {
        path: '/app/cuas',
        component: () => (
          <Suspense fallback={PageLoading}>
            <CuasInboxPage />
          </Suspense>
        ),
        hideInMenu: true,
        hideHeader: true,
        name: 'Certificados CUA',
        key: 'cuas',
        backgroundColor: '#F3FAFF',
      },
      {
        path: '/app/createCua',
        component: () => (
          <Suspense fallback={PageLoading}>
            <CuaAdministrationPage />
          </Suspense>
        ),
        hideInMenu: true,
        hideHeader: true,
        name: 'Crear CUA',
        key: 'createCua',
      },
      {
        path: '/app/detailed-reports',
        component: () => (
          <Suspense fallback={PageLoading}>
            <DetailedReportsPage />
          </Suspense>
        ),
        hideInMenu: true,
        hideHeader: true,
        name: 'Informes detallados',
        key: 'detailedReports',
      },
      {
        path: '/app/cua-log/:id',
        component: () => (
          <Suspense fallback={PageLoading}>
            <CuaLogPage />
          </Suspense>
        ),
        hideInMenu: true,
        hideHeader: true,
        name: 'Ver Logs',
        key: 'log',
        backgroundColor: '#F3FAFF',
      },
      {
        path: '/app/complaints/:cua_id',
        component: () => (
          <Suspense fallback={PageLoading}>
            <ComplaintsPage />
          </Suspense>
        ),
        hideInMenu: true,
        hideHeader: true,
        name: 'Denuncias',
        key: 'complaints',
        backgroundColor: '#F3FAFF',
      },
      {
        path: '/app/createComplaint/:cua_id',
        component: () => (
          <Suspense fallback={PageLoading}>
            <ManageComplaintPage />
          </Suspense>
        ),
        hideInMenu: true,
        hideHeader: true,
        name: 'Iniciar denuncia',
        key: 'createComplaint',
      },
      {
        path: '/app/complaintDetail/:complaint_id',
        component: () => (
          <Suspense fallback={PageLoading}>
            <ComplaintDetailPage />
          </Suspense>
        ),
        hideInMenu: true,
        hideHeader: true,
        name: 'Detalle',
        key: 'complaintDetail',
        backgroundColor: '#FFFFFF',
      },
      {
        path: '/app/administration',
        name: 'Administración',
        component: EmptyLayout,
        authority: EnumsValues.Functions.AdministrationMenuRead,
        key: 'administration',
        routes: [
          {
            path: '/app/administration/role',
            component: () => (
              <Suspense fallback={PageLoading}>
                <RolePage />
              </Suspense>
            ),
            authority: EnumsValues.Functions.UserRoleList,
            name: 'Roles',
            key: 'role',
          },
          {
            path: '/app/administration/permission',
            component: () => (
              <Suspense fallback={PageLoading}>
                <PermissionPage />
              </Suspense>
            ),
            authority: EnumsValues.Functions.PermissionList,
            name: 'Permisos',
            key: 'permission',
          },
          {
            path: '/app/administration/user',
            component: (props: any) => (
              <Suspense fallback={PageLoading}>
                <UserPage {...props} />
              </Suspense>
            ),
            authority: EnumsValues.Functions.UserList,
            name: 'Usuarios',
            key: 'userPage',
          },
          redirectIfNotExist,
        ],
      },
      {
        path: '/app/setting',
        authority: EnumsValues.Functions.AppSettingRead,
        component: () => (
          <Suspense fallback={PageLoading}>
            <AppSettingPage />
          </Suspense>
        ),
        name: 'Configuración',
        key: 'setting',
      },
      {
        path: '/app/termsandconditions',
        component: () => (
          <Suspense fallback={PageLoading}>
            <TermsAndConditionsPage mode="registered" />
          </Suspense>
        ),
        name: 'Términos y condiciones',
        key: 'termsAndConditions',
      },
      {
        path: '/app/privacy-policies',
        component: () => (
          <Suspense fallback={PageLoading}>
            <TermsAndConditionsPage mode="registered" goTo="privacy-policies" />
          </Suspense>
        ),
        name: 'Políticas de Privacidad',
        key: 'privacy-policies',
      },
      {
        path: '/app/contact',
        component: () => (
          <Suspense fallback={PageLoading}>
            <HelpPage />
          </Suspense>
        ),
        name: 'Contacto',
        key: 'contact',
      },
      redirectIfNotExist,
    ],
  },
  {
    path: '/',
    component: (props: any) => (
      <Suspense fallback={PageLoading}>
        <PublicLayout {...props} />
      </Suspense>
    ),
    key: 'publicLayout',
    routes: [
      {
        path: '/resetPassword',
        exact: true,
        component: () => (
          <Suspense fallback={PageLoading}>
            <ResetPasswordContentPage />
          </Suspense>
        ),
        key: 'resetPassword',
      },
      {
        exact: true,
        path: '/setPassword',
        component: () => (
          <Suspense fallback={PageLoading}>
            <SetPasswordContentPage />
          </Suspense>
        ),
        key: 'setPassword',
      },
      {
        exact: true,
        path: '/completeRegister',
        component: () => (
          <Suspense fallback={PageLoading}>
            <CompleteRegisterPage />
          </Suspense>
        ),
        key: 'completeRegister',
      },
      {
        path: '/login',
        exact: true,
        component: (props: any) => (
          <Suspense fallback={PageLoading}>
            <LoginPage {...props} />
          </Suspense>
        ),
        key: 'login',
      },
      {
        path: '/register',
        exact: true,
        component: () => (
          <Suspense fallback={PageLoading}>
            <RegisterPage />
          </Suspense>
        ),
        key: 'register',
        showReturnToLogin: true,
      },
      {
        path: '/reminder',
        exact: true,
        component: () => (
          <Suspense fallback={PageLoading}>
            <Reminder2FA />
          </Suspense>
        ),
        key: 'reminder',
      },
      {
        path: '/generate',
        exact: true,
        component: (props: any) => (
          <Suspense fallback={PageLoading}>
            <Generate2FA {...props} />
          </Suspense>
        ),
        key: 'generate',
      },
      {
        path: '/validateTotp',
        exact: true,
        component: () => (
          <Suspense fallback={PageLoading}>
            <Validate2FA />
          </Suspense>
        ),
        key: 'validateTotp',
      },
      redirectIfNotExist,
    ],
  },
];

export default routes;

export const UPSERT_IDENTITY_PICTURES = `
mutation upsertIdentityPictures($input: IdentityPicturesInput!){
  upsertIdentityPictures(input: $input){
    __typename
    ...on ResponseMessage{
      status
      response
    }
    ...on ResultError{
      status_code
      message
    }
  }
}
`;

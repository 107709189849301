export const CREATE_COMMENT_COMPLAINT = `
mutation createComplaintComment($input: CreateComplaintComment!){
    createComplaintComment(input:$input){
      __typename
      ...on ResponseMessage{
        status
        response
      }
      ...on ResultError{
        status_code
        message
      }
    }
  }
`;

import { EnumsValues } from '../enums/EnumsValues';
import { IInvitation } from './Invitation';
import { ILocation } from './Location';
import { IPersonProperty } from './PersonProperty';
import { IPropertyType } from './PropertyType';

export interface IProperty {
  id: number;
  name?: string | null;
  plant: number;
  status: number;
  postal_code: string;
  location_id: number;
  address: string;
  floor?: string | null;
  department?: string | null;
  registration: string;
  area: number;
  covered_area?: number | null;
  purpose: number;
  property_type_id: number;
  location?: ILocation | null;
  tome?: string | undefined;
  folio?: string | undefined;
  additional_person?: number | undefined;
  person_property?: IPersonProperty[] | null;
  invitation?: IInvitation[] | null;
  property_type?: IPropertyType | null;
}

export interface IFormCreateProperty {
  property_type_id: number;
  name?: string;
  postal_code: string;
  province_id?: number | null;
  location_id: number;
  address: string;
  floor?: string;
  department?: string;
  registration?: string;
  tome?: string;
  folio?: string;
  plot_number: string;
  plant: number;
  area: number;
  covered_area?: number;
  purpose: number;
}

export interface ICreatePropertyInput {
  person_id: number;
  name?: string | undefined;
  plant: number;
  status?: number | undefined;
  postal_code: string;
  location_id: number;
  address: string;
  floor?: string | undefined;
  department?: string | undefined;
  registration?: string | undefined;
  area: number;
  covered_area?: number | undefined;
  purpose: number;
  property_type_id: number;
  plot_number: string;
  tome?: string | undefined;
  folio?: string | undefined;
  additional_person?: number | undefined;
}

export interface ICreatePropertyInput {
  person_id: number;
  name?: string | undefined;
  plant: number;
  status?: number | undefined;
  postal_code: string;
  location_id: number;
  address: string;
  floor?: string | undefined;
  department?: string | undefined;
  registration?: string | undefined;
  area: number;
  covered_area?: number | undefined;
  purpose: number;
  property_type_id: number;
  plot_number: string;
  tome?: string | undefined;
  folio?: string | undefined;
  additional_person?: number | undefined;
}

export interface IUpdatePropertyInput {
  id: number;
  person_id?: number;
  name?: string;
  plant?: number;
  status?: number;
  postal_code?: string;
  location_id?: number;
  address?: string;
  floor?: string;
  department?: string;
  registration?: string;
  area?: number;
  covered_area?: number;
  purpose?: number;
  property_type_id?: number;
  plot_number?: string;
  tome?: string;
  folio?: string;
  additional_person?: number;
}

export interface IPropertyFilterInput {
  id?: number | undefined;
  cua_role_id?: number | undefined;
  person_type_id?: number | undefined;
  property_type_id?: number | undefined;
  available?: boolean | undefined;
  busy?: boolean | undefined;
}

export const PROPERTY_PLANTS_LIST = [
  {
    label: EnumsValues.PropertyPlantLabels.Rural,
    value: EnumsValues.PropertyPlantIds.Rural,
  },
  {
    label: EnumsValues.PropertyPlantLabels.Subrural,
    value: EnumsValues.PropertyPlantIds.Subrural,
  },
  {
    label: EnumsValues.PropertyPlantLabels.Urban,
    value: EnumsValues.PropertyPlantIds.Urban,
  },
];

export const PROPERTY_PURPOSE_LIST = [
  {
    label: EnumsValues.PropertyPurposeLabels.Commercial,
    value: EnumsValues.PropertyPurposeIds.Commercial,
  },
  {
    label: EnumsValues.PropertyPurposeLabels.Industrial,
    value: EnumsValues.PropertyPurposeIds.Industrial,
  },
  {
    label: EnumsValues.PropertyPurposeLabels.Residential,
    value: EnumsValues.PropertyPurposeIds.Residential,
  },
  {
    label: EnumsValues.PropertyPurposeLabels.Tourist,
    value: EnumsValues.PropertyPurposeIds.Tourist,
  },
];

export const DEFAULT_ORDER_PROPERTIES = {
  field: 'id',
  direction: 'desc',
};

import { renderRoutes } from 'react-router-config';
import routes from './routes/routes';
import SendActivity from './sendActivity';

const AppContent = () => {
  SendActivity();
  return renderRoutes(routes);
};

export default AppContent;

enum InvoiceSetStatus {
  Open = 1,
  Closed = 2,
}

enum InvoiceStatus {
  NoPresentado = 1,
  Incompleto = 2,
  PorConformar = 3,
  Procesado = 4,
  Conformado = 5,
}

enum UserRoleSystem {
  Admin = 1,
  Tenant = 2,
  Owner = 3,
}

enum UserRoleName {
  Admin = 'Administrador',
  Tenant = 'Inquilino',
  Owner = 'Gestor / Propietario',
}

enum ReturnFileType {
  ReturnInvoice = 1,
  ReturnInvoiceDetail = 2,
  CheckList = 3,
  OutputTop = 4,
}

enum ReturnOrderStatus {
  HD_NEW = 1,
  HD_In_Progress = 2,
  CD_NEW = 3,
  CD_In_Progress = 4,
  CD_DF_NEW = 5,
  CD_DF_In_Progress = 6,
  RL_NEW = 7,
  RL_In_Progress = 8,
  RL_Fiscal_Review = 9,
  QL_NEW = 10,
  QL_In_Progress = 11,
  FN_NEW = 12,
  FN_In_Progress = 13,
  Closed = 14,
}

enum Functions {
  AppSettingRead = 'APP_SETTING_R',
  AppSettingUpdate = 'APP_SETTING_U',
  PermissionGet = 'PERMISSION_G',
  PermissionList = 'PERMISSION_L',
  PermissionUpdate = 'PERMISSION_U',
  RoleCreate = 'ROLE_C',
  RoleDelete = 'ROLE_D',
  RoleGet = 'ROLE_G',
  RoleList = 'ROLE_L',
  RoleUpdate = 'ROLE_U',
  RolePermissionCreate = 'ROLE_PERMISSION_C',
  RolePermissionGet = 'ROLE_PERMISSION_G',
  RolePermissionDelete = 'ROLE_PERMISSION_D',
  RolePermissionList = 'ROLE_PERMISSION_L',
  RolePermissionUpdate = 'ROLE_PERMISSION_U',
  UserCreate = 'USER_C',
  UserDelete = 'USER_D',
  UserGet = 'USER_G',
  UserList = 'USER_L',
  UserRoleGet = 'USER_ROLE_G',
  UserRoleList = 'USER_ROLE_L',
  UserRoleUpdate = 'USER_ROLE_U',
  UserUpdate = 'USER_U',
  AdministrationMenuRead = 'ADMINISTRATION_MENU_R',
  PersonUpdate = 'PERSON_U',
  PersonCreate = 'PERSON_C',
  PersonDelete = 'PERSON_D',
  PersonRead = 'PERSON_R',
  ReadCuaStatus = 'CUA_STATUS_R',
  CreateProperty = 'PROPERTY_C',
  ReadProperty = 'PROPERTY_R',
  UpdateProperty = 'PROPERTY_U',
  DeleteProperty = 'PROPERTY_D',
  CreateProvince = 'PROVINCE_C',
  ReadProvince = 'PROVINCE_R',
  UpdateProvince = 'PROVINCE_U',
  DeleteProvince = 'PROVINCE_D',
  CreateDepartment = 'DEPARTMENT_C',
  ReadDepartment = 'DEPARTMENT_R',
  UpdateDepartment = 'DEPARTMENT_U',
  DeleteDepartment = 'DEPARTMENT_D',
  CreateLocation = 'LOCATION_C',
  ReadLocation = 'LOCATION_R',
  UpdateLocation = 'LOCATION_U',
  DeleteLocation = 'LOCATION_D',
  ReadNotification = 'NOTIFICATION_R',
  DeleteNotification = 'NOTIFICATION_D',
  IsAdmin = 'IS_ADMIN',
  IsOwner = 'IS_OWNER',
  IsTenant = 'IS_TENANT',
  UserProfileUpdate = 'USER_PROFILE_U',
}

enum InvoiceAction {
  FacturaImportada = 1,
  FacturaIncompleta = 2,
  FacturaParaConformar = 3,
  FacturaProcesada = 4,
  FacturaConformada = 5,
  ImagenAgregada = 6,
  ValletPallet = 7,
  NotaCreditoImportada = 8,
  ConformidadPOD = 9,
  TimbreMesaAyuda = 10,
  LogisticaReversa = 11,
  DetalleRecepción = 12,
  Comentario = 13,
  FacturaReImportada = 14,
  NotaCreditoReImportada = 15,
  FactRechazadaTranportista = 16,
  FactReasignadaTransportista = 17,
  PagoAsociadoFctura = 18,
  ImagenBorrada = 19,
  ValletPalletRemovido = 20,
  ConformidadPODRemovida = 21,
  TimbreMesaAyudaRemovido = 22,
  LogisticaReversaRemovida = 23,
  DetalleRecepcionRemovido = 24,
  FactRequeridaTransportista = 25,
}
enum InvoiceFileColumns {
  dt_number = ' Nro Transporte',
  delivery_number = 'Nro. Entrega',
  invoice_number = 'Nro Factura',
  legal_number = 'Numero Legal de Factura',
  billing_date = 'Fe Fat Real',
  receiver = 'Destinatario de Merc',
  submitter = 'Desc. Solicitante',
  quantity = 'Cantidad Facturada',
  net_value = 'Valor Neto Factura',
  sales_organization = 'Org. Ventas',
  address = 'Dirección',
  location = 'Población',
  region_sap_id = 'Región',
  carrier_sap_account = 'Número de cuenta del proveedor',
  payment_condition_sap_id = 'Cond. Pago',
}

enum InvoiceSapValidatedFileColumns {
  dt_number = 'Documento de Transporte',
  delivery_number = 'Entrega',
  invoice_number = 'Factura',
  legal_number = 'Numero NF',
  billing_date = 'Fecha de Factura',
  pod_date = 'Fecha de POD',
  validate_date = 'Fecha de Conforme Provisorio',
  modify_by = 'Modificado por',
}

enum CreditNoteFileColumns {
  order_number = ' Nro Orden',
  client_order_number = 'Nro. OC Cliente',
  request_date = 'Fecha del Pedido',
  nc_sales_organization = 'Org. Ventas',
  invoice_legal_number = 'Numero Legal de Factura',
  nc_billing_date = 'Fe Fat Real',
  nc_quantity = 'Cantidad Facturada',
  nc_net_value = 'Valor Neto Factura',
  nc_submitter = 'Desc. Solicitante',
  nc_receiver = 'Destinatario de Merc',
  nc_invoice_number = 'Nro Factura',
  order_creator = 'Creado por Orden',
  sales_document_class = 'Clase Doc.Ventas',
}

/**
 * Numeric Types
 * Name	Storage Size	Range
 * smallint	2 bytes	-32768 to +32767
 * integer	4 bytes	-2147483648 to +2147483647
 * bigint	8 bytes	-9223372036854775808 to 9223372036854775807
 */
enum MaxLengthInputs {
  SystemUser_UserName = 20,
  SystemUser_Email = 100,
  StringInputs = 200,
  NumberInputs = 20,
  NumberInputsMaxInt = 2147483647,
  NumberInputsMaxBigInt = 9223372036854775807,
  CodeSap = 4,
  Region_DeliveryTerm = 2,
}

enum Inboxes {
  HelpDesk = 1,
  ControlDesk = 2,
  ReverseLogistic = 3,
  Quality = 4,
  Finance = 5,
  Todos = 6,
}

enum LocalStorageKeys {
  RoleSelected = 'role_selected',
  TranslationsVersions = 'translation_versions',
  Translations = 'translations',
  TwoFactorValidated = 'two_factor_validated',
  Token = 'token',
  UserId = 'userId',
  TwoFAOmitted = 'TwoFAOmitted',
  BackUrl = 'back_url',
  EmailPreloadToLoginRegister = 'email_preload_to_login_register',
  CuaCreatedId = 'cua_created_id',
  BackUrlGenerateCua = 'back_url_generate_cua',
  StepStoredToGenerateCua = 'step_stored_to_generate_cua',
  EmailToInviteTenantToParticipateInCua = 'email_to_invite_tenant_to_participate_in_cua',
  TenantIdToAcceptCua = 'tenant_id_to_accept_cua',
  UserFirebaseMessagingId = 'user_fcm_id',
}

enum TwoFactorValidatedValues {
  True = 'true',
  False = 'false',
}

export enum SystemLimits {
  //Se define limite de tamaño de archivos en bytes
  /*
    Recordar que este enum debe corresponder con el de backend.
  */
  MaxSizeOfFiles = 20000000, //20 MB,
}

export enum ErrorsStatusCode {
  userIsAlreadyValidatedCode = 64,
  passwordInvalidErrorCode = 51,
}

export enum DefaultValues {
  Title = 'CUA',
}

export enum SettingNames {
  RecoveryPasswordFrom = 'RECOVERY_PASSWORD_FROM',
  RecoveryPasswordSubject = 'RECOVERY_PASSWORD_SUBJECT',
  NodeMailerHost = 'NODEMAILER_HOST',
  NodeMailerPort = 'NODEMAILER_PORT',
  NodeMailerSecure = 'NODEMAILER_SECURE',
  NodeMailerUser = 'NODEMAILER_USER',
  NodeMailerPassword = 'NODEMAILER_PASSWORD',
  NodeMailerAccountType = 'NODEMAILER_ACCOUNT_TYPE',
  UrlSite = 'URL_SITE',
  PasswordRegex = 'PASSWORD_REGEX',
  MinBornDate = 'MIN_BORN_DATE',
  ProfileRequired = 'PROFILE_REQUIRED',
  SecurityTokenExpiresin = 'SECURITY_TOKEN_EXPIRESIN',
  SecurityRefreshTokenExpiresin = 'SECURITY_REFRESH_TOKEN_EXPIRESIN',
  FirstLogin = 'FIRST_LOGIN',
  LimitMaxSizeFile = 'LIMIT_MAX_SIZE_FILE',
  GAClientID = 'GA_ID',
  ValidateUserByEmail = 'VALIDATE_USER_BY_EMAIL',
  UserPublicRegister = 'USERS_PUBLIC_REGISTER',
  PageTitle = 'PAGE_TITLE',
  LimitPropertyPictures = 'LIMIT_PROPERTY_PICTURES',
  ComplaintExpiration = 'COMPLAINT_EXPIRATION',
  ContactEmail = 'CONTACT_EMAIL',
  contactUrl = 'CONTACT_URL',
  ComplaintComment = 'COMPLAINT_COMMENT',
}

/* Recordar: si se quiere agregar un nuevo object store hay que realizar la declaracion correspondiente en el archivo IndexedDb.ts */
export enum ObjectStore {
  Company = 'companies',
  PostponedActions = 'postponedActions',
  Operation = 'operations',
}

export enum ExportFormat {
  xlsx = 'xlsx',
  csv = 'csv',
  txt = 'txt',
}

export enum IdTypeId {
  Passport = 1,
  FiscalId = 2,
}

export enum IdTypeLabel {
  Passport = 'Pasaporte',
  FiscalId = 'CUIT/CUIL',
}

export enum MaritalStatusId {
  Single = 1,
  Married = 2,
  Divorced = 3,
  Widower = 4,
  CohabitationUnion = 5,
}

export enum MaritalStatusLabel {
  Single = 'Soltero',
  Married = 'Casado',
  Divorced = 'Divorciado',
  Widower = 'Viudo',
  CohabitationUnion = 'Unión convivencial',
}

export enum IdentityPictures {
  Front = 1,
  Back = 2,
}
export enum PathRoutes {
  TermsAndConditionsRegistered = '/app/termsandconditions',
  TermsAndConditions = '/termsandconditions',
  Onboarding = '/app/onboarding',
}

export enum RegularExpresions {
  Username = '^[A-Z0-9_-]+$',
  AlphanumericWithoutSpaces = '^[a-zA-Z0-9]+$',
  CuitCuil = '^\\d{11}$',
}

enum PersonValidated {
  Validated = 1,
  NotValidated = 0,
}

enum PropertyPlantIds {
  Urban = 1,
  Rural = 2,
  Subrural = 3,
}

enum PropertyPlantLabels {
  Urban = 'Urbana',
  Rural = 'Rural',
  Subrural = 'Subrural',
}

enum PropertyPurposeIds {
  Commercial = 1,
  Industrial = 2,
  Residential = 3,
  Tourist = 4,
}

enum PropertyPurposeLabels {
  Commercial = 'Comercial',
  Industrial = 'Industrial',
  Residential = 'Residencial',
  Tourist = 'Turístico',
}

enum PropertyRoleOwner {
  Owner = 5,
  Additional = 6,
  Manager = 8,
}

enum PropertyOcupationalStatus {
  Free = 1,
  Busy = 2,
}

enum PersonTypeIds {
  Physical = 1,
  Legal = 2,
}

enum InvitationType {
  Tenant = 1,
  Owner = 2,
  AdicionalTenant = 3,
  Spouse = 4,
  Manager = 5,
  AdditionalOwner = 6,
}

enum QueryStringsParameters {
  InvitationId = 'inv_id',
  DestinationEmail = 'destination_email',
}

enum PropertyAttributesValues {
  WithDetails = 1,
  GoodStatus = 2,
}

enum RentType {
  Temporary = 1,
  Permanent = 2,
}

export enum NotificationType {
  ManagerRemoved = 1,
  InvitationExpired = 2,
  InvitationRejected = 3,
  InvitationAccepted = 4,
  InvitationCuaTenant = 5,
  InvitationCuaTenantExpired = 6,
  InvitationToPropertyManager = 7,
  InvitationRejectedByTenant = 8,
  InvitationAcceptedByTenant = 9,
  CuaApproved = 10,
  CuaRejected = 11,
  NewComplaint = 12,
  ComplaintCancelled = 13,
  ComplaintResolved = 14,
  CuaFinalized = 15,
  ComplaintComment = 16,
  ComplaintRequestedAdmin = 17,
  InvitationForManagerExpired = 18,
  InvitationForTenantExpired = 19,
}

enum DocumentationTypeEnum {
  IdentityDocument = 1,
  ProfilePicture = 2,
  PropertyStatus = 3,
  Agreement = 4,
}

enum CuaRoles {
  Tenant = 1,
  AdditionalTenant = 2,
  SpouseTenant = 3,
  RepresentativeTenant = 4,
  Owner = 5,
  AdditionalOwner = 6,
  RepresentativeOwner = 7, //Apoderado propietario
  Manager = 8, //Gestor
  SpouseOwner = 9, //Conyuge propietario
}

enum BackError {
  notFound = 2,
}

enum PropertyAttributesNames {
  Other = 'Otro',
}

enum ComplaintStatus {
  InProgress = 1,
  Finished = 2,
  Canceled = 3,
}

enum CuaMainRoles {
  Tenant = 1,
  Owner = 2,
  System = 3,
}
enum MimeTypes {
  imagesPng = 'image/png',
  imagesJpg = 'image/jpg',
  imagesJpeg = 'image/jpeg',
  applicationPdf = 'application/pdf',
}
enum CuaStatus {
  Draft = 1,
  PendingTenantApproval = 2,
  PendingPayment = 3,
  ApprovedByTenant = 4,
  Active = 5,
  Finished = 6,
  RejectedByTenant = 7,
  RejectedByOwner = 8,
  Rescinded = 9,
  PendingByAdditionalTenant = 10,
}

export class EnumsValues {
  static readonly BackError = BackError;

  readonly BackError = EnumsValues.BackError;

  static readonly NotificationType = NotificationType;

  readonly NotificationType = EnumsValues.NotificationType;

  static readonly Functions = Functions;

  readonly Functions = EnumsValues.Functions;

  static readonly InvoiceFileColumns = InvoiceFileColumns;

  readonly InvoiceFileColumns = EnumsValues.InvoiceFileColumns;

  static readonly InvoiceSapValidatedFileColumns =
    InvoiceSapValidatedFileColumns;

  readonly InvoiceSapValidatedFileColumns =
    EnumsValues.InvoiceSapValidatedFileColumns;

  static readonly InvoiceSetStatus = InvoiceSetStatus;

  readonly InvoiceSetStatus = EnumsValues.InvoiceSetStatus;

  static readonly InvoiceStatus = InvoiceStatus;

  readonly InvoiceStatus = EnumsValues.InvoiceStatus;

  static readonly UserRoleSystem = UserRoleSystem;

  readonly UserRoleSystem = EnumsValues.UserRoleSystem;

  static readonly InvoiceAction = InvoiceAction;

  readonly InvoiceAction = EnumsValues.InvoiceAction;

  static readonly MaxLengthInputs = MaxLengthInputs;

  readonly MaxLengthInputs = EnumsValues.MaxLengthInputs;

  static readonly CreditNoteFileColumns = CreditNoteFileColumns;

  readonly CreditNoteFileColumns = EnumsValues.CreditNoteFileColumns;

  static readonly ReturnFileType = ReturnFileType;

  readonly ReturnFileType = EnumsValues.ReturnFileType;

  static readonly ReturnOrderStatus = ReturnOrderStatus;

  readonly ReturnOrderStatus = EnumsValues.ReturnOrderStatus;

  static readonly Inboxes = Inboxes;

  readonly Inboxes = EnumsValues.Inboxes;

  static readonly LocalStorageKeys = LocalStorageKeys;

  readonly LocalStorageKeys = EnumsValues.LocalStorageKeys;

  static readonly TwoFactorValidatedValues = TwoFactorValidatedValues;

  readonly TwoFactorValidatedValues = EnumsValues.TwoFactorValidatedValues;

  static readonly SettingNames = SettingNames;

  readonly SettingNames = EnumsValues.SettingNames;

  static readonly SystemLimits = SystemLimits;

  readonly SystemLimits = EnumsValues.SystemLimits;

  static readonly ErrorsStatusCode = ErrorsStatusCode;

  readonly ErrorsStatusCode = EnumsValues.ErrorsStatusCode;

  static readonly DefaultValues = DefaultValues;

  readonly DefaultValues = EnumsValues.DefaultValues;

  static readonly IdTypeId = IdTypeId;

  readonly IdTypeId = EnumsValues.IdTypeId;

  static readonly IdTypeLabel = IdTypeLabel;

  readonly IdTypeLabel = EnumsValues.IdTypeLabel;

  static readonly MaritalStatusLabel = MaritalStatusLabel;

  readonly MaritalStatusLabel = EnumsValues.MaritalStatusLabel;

  static readonly MaritalStatusId = MaritalStatusId;

  readonly MaritalStatusId = EnumsValues.MaritalStatusId;

  static readonly IdentityPictures = IdentityPictures;

  readonly IdentityPictures = EnumsValues.IdentityPictures;

  static readonly PathRoutes = PathRoutes;

  readonly PathRoutes = EnumsValues.PathRoutes;

  static readonly RegularExpressions = RegularExpresions;

  readonly RgularExpressions = EnumsValues.RegularExpressions;

  static readonly PersonValidated = PersonValidated;

  readonly PersonValidated = EnumsValues.PersonValidated;

  static readonly UserRoleName = UserRoleName;

  readonly UserRoleName = EnumsValues.UserRoleName;

  static readonly PropertyPlantIds = PropertyPlantIds;

  readonly PropertyPlantIds = EnumsValues.PropertyPlantIds;

  static readonly PropertyPlantLabels = PropertyPlantLabels;

  readonly PropertyPlantLabels = EnumsValues.PropertyPlantLabels;

  static readonly PropertyPurposeIds = PropertyPurposeIds;

  readonly PropertyPurposeIds = EnumsValues.PropertyPurposeIds;

  static readonly PropertyPurposeLabels = PropertyPurposeLabels;

  readonly PropertyPurposeLabels = EnumsValues.PropertyPurposeLabels;

  static readonly PropertyRoleOwner = PropertyRoleOwner;

  readonly PropertyRoleOwner = EnumsValues.PropertyRoleOwner;

  static readonly PropertyOcupationalStatus = PropertyOcupationalStatus;

  readonly PropertyOcupationalStatus = EnumsValues.PropertyOcupationalStatus;

  static readonly PersonTypeIds = PersonTypeIds;

  readonly PersonTypeIds = EnumsValues.PersonTypeIds;

  static readonly InvitationType = InvitationType;

  readonly InvitationType = EnumsValues.InvitationType;

  static readonly QueryStringsParameters = QueryStringsParameters;

  readonly QueryStringsParameters = EnumsValues.QueryStringsParameters;

  static readonly PropertyAttributesValues = PropertyAttributesValues;

  readonly PropertyAttributesValues = EnumsValues.PropertyAttributesValues;

  static readonly RentType = RentType;

  readonly RentType = EnumsValues.RentType;

  static readonly DocumentationTypeEnum = DocumentationTypeEnum;

  readonly DocumentationTypeEnum = EnumsValues.DocumentationTypeEnum;

  static readonly CuaRoles = CuaRoles;

  readonly CuaRoles = EnumsValues.CuaRoles;

  static readonly PropertyAttributesNames = PropertyAttributesNames;

  readonly PropertyAttributesNames = EnumsValues.PropertyAttributesNames;

  static readonly ComplaintStatus = ComplaintStatus;

  readonly ComplaintStatus = EnumsValues.ComplaintStatus;

  static readonly CuaMainRoles = CuaMainRoles;

  readonly CuaMainRoles = EnumsValues.CuaMainRoles;

  static readonly MimeTypes = MimeTypes;

  readonly MimeTypes = EnumsValues.MimeTypes;

  static readonly CuaStatus = CuaStatus;

  readonly CuaStatus = EnumsValues.CuaStatus;

  constructor(init?: Partial<EnumsValues>) {
    Object.assign(this, init);
  }
}

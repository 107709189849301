export const CUA_STATUS_LIST = `
query cuaStatusList(
  $filter: CuaStatusFilterInput, 
  $orderBy: CuaStatusOrderInput, 
  $skip: Int, 
  $take: Int
){
cuaStatusList(
  filter:$filter, 
  orderBy:$orderBy, 
  skip:$skip, 
  take:$take
){
  __typename
  ...on CuaStatus{
    id
    name
  }
  ...on ResultError{
    status_code
    message
  }
}
}
`;

import { useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { ContextApp } from '../../contexts/ContextApp';
import { EnumsValues } from '../../enums/EnumsValues';
import { Authorization } from '../../shared';
const DefaultRedirectPage = () => {
  const { user, functions } = useContext(ContextApp);
  if (user) {
    if (
      !user.authenticated &&
      !Authorization.security(functions, EnumsValues.Functions.IsAdmin)
    )
      return <Redirect to="/app/onboarding" />;
    if (
      !user.accepted_terms_and_conditions &&
      !Authorization.security(functions, EnumsValues.Functions.IsAdmin)
    )
      return <Redirect to="/app/termsandconditions" />;
    if (localStorage.getItem(EnumsValues.LocalStorageKeys.BackUrl))
      return (
        <Redirect
          to={localStorage.getItem(EnumsValues.LocalStorageKeys.BackUrl)}
        />
      );
    return <Redirect to="/app/home" />;
  } else {
    return <Redirect to="/login" />;
  }
};

export default DefaultRedirectPage;

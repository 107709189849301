import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { NavContext } from '@ionic/react';
import { Button, Empty, Spin } from 'antd';
import { PageLoading } from '@ant-design/pro-layout';
import InfiniteScroll from 'react-infinite-scroll-component';

import { ContextApp } from '../../contexts/ContextApp';
import GraphqlService from '../../services/graphql/GraphqlService';
import {
  DEFAULT_ORDER_PROPERTIES,
  IProperty,
  IPropertyFilterInput,
} from '../../interfaces/Property';
import { getPaginationArgs } from '../../shared/getPaginationArgs';
import { CustomMessage, useCheckAuthority, useCurrentRoute } from '../../hooks';
import CardGray from '../../components/common/CardGray/CardGray';
import { EnumsValues } from '../../enums/EnumsValues';
import { FilterProperties } from '../../components/common/FilterProperties/FilterProperties';
import ButtonFilter from '../../components/common/ButtonFilter/ButtonFilter';

import './MyPropertiesPage.less';
import { IPerson } from '../../interfaces/Person';
import CardNavigation from '../../components/common/CardNavigation/CardNavigation';
import { UserAddOutlined } from '@ant-design/icons';

interface IScroll {
  hasMoreProperties: boolean;
  propertiesPage: number;
}

const scrollInitial: IScroll = {
  propertiesPage: 1,
  hasMoreProperties: true,
};

const PROPERTIES_PER_REQUEST = 30;

const MyPropertiesPage = () => {
  const { t, user } = useContext(ContextApp);
  const person: IPerson | undefined = user?.person.length
    ? user.person[0]
    : undefined;
  const isPersonValidated =
    person?.validated === EnumsValues.PersonValidated.Validated;
  const { navigate } = useContext(NavContext);
  const [open, setOpen] = useState<boolean>(false);
  const [properties, setProperties] = useState<IProperty[]>([]);
  const [loadingProperties, setLoadingProperties] =
    useState<boolean>(isPersonValidated);
  const [scroll, setScroll] = useState<IScroll>(scrollInitial);
  const [filterProperties, setFilterProperties] =
    useState<IPropertyFilterInput | undefined>();
  const { leafRoute } = useCurrentRoute();
  const { customRequest, Query } = GraphqlService();
  const { showMessageError } = CustomMessage();
  const dataFetchedRef = useRef<boolean>(false);

  const getProperties = useCallback(
    async (args?: { initial?: boolean; filter?: IPropertyFilterInput }) => {
      const { skip, take } = getPaginationArgs(
        PROPERTIES_PER_REQUEST,
        args?.initial ? 1 : scroll.propertiesPage,
      );
      if (args?.initial) setLoadingProperties(true);
      try {
        const response: IProperty[] = await customRequest({
          query: Query.propertyList,
          variables: {
            skip,
            take,
            orderBy: DEFAULT_ORDER_PROPERTIES,
            filter: args?.filter,
          },
        });
        if (args?.initial) {
          setProperties(response);
        } else {
          setProperties([...properties, ...response]);
        }
        setScroll((oldState) => {
          const newState: IScroll = JSON.parse(JSON.stringify(oldState));
          newState.hasMoreProperties =
            response.length >= PROPERTIES_PER_REQUEST;
          newState.propertiesPage++;
          return newState;
        });
      } catch (error: any) {
        showMessageError({
          context: 'MyPropertiesPage.getProperties.1',
          error,
        });
      }
      if (args?.initial) setLoadingProperties(false);
    },
    [scroll, filterProperties],
  );

  useEffect(() => {
    if (dataFetchedRef.current || !isPersonValidated) return;
    dataFetchedRef.current = true;
    getProperties({ initial: true });
  }, []);

  return (
    useCheckAuthority(leafRoute) || (
      <>
        <div className="properties-page">
          {loadingProperties ? (
            <PageLoading />
          ) : (
            <>
              {!isPersonValidated ? (
                <>
                  <CardNavigation
                    icon={<UserAddOutlined />}
                    title={t(
                      'message.ownerDoNotHavePersonalDataRegisteredTitle',
                    )}
                    description={t(
                      'message.ownerDoNotHavePersonalDataRegisteredDescriptionToRegisterProperty',
                    )}
                    onClick={() => navigate('/app/personalData')}
                  />
                </>
              ) : (
                <>
                  <ButtonFilter setOpen={setOpen} />
                  <div
                    id="scrollableDiv"
                    className="properties-page__container-content"
                    style={{ overflowY: 'auto' }}
                  >
                    {!properties.length ? (
                      <>
                        <div className="properties-page__container-content__container-empty">
                          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        </div>
                      </>
                    ) : (
                      <>
                        <InfiniteScroll
                          className="properties-page__container-content__content"
                          dataLength={properties.length}
                          next={() => {
                            getProperties(
                              filterProperties
                                ? { filter: { ...filterProperties } }
                                : undefined,
                            );
                          }}
                          hasMore={scroll.hasMoreProperties}
                          loader={
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                marginTop: '8px',
                              }}
                            >
                              <Spin />
                            </div>
                          }
                          scrollableTarget="scrollableDiv"
                        >
                          <>
                            {properties.map((property) => (
                              <>
                                <CardGray
                                  onClick={() =>
                                    navigate(`/app/property/${property.id}`)
                                  }
                                  className="properties-page__container-content__content__card"
                                >
                                  <span>
                                    <b>
                                      {t('entity.id')} {property.id}
                                    </b>
                                  </span>
                                  {property.property_type && (
                                    <>
                                      <span>
                                        {property.property_type.name}{' '}
                                        {property.name && `- ${property.name}`}{' '}
                                      </span>
                                    </>
                                  )}
                                  <span>{property.address}</span>
                                  {property.location && (
                                    <>
                                      <span>
                                        {property.location.name},{' '}
                                        {
                                          property.location.department.province
                                            .name
                                        }
                                      </span>
                                    </>
                                  )}
                                  <span>
                                    {property.area}{' '}
                                    {t('entity.squareMetersShortMode')}
                                  </span>
                                </CardGray>
                              </>
                            ))}
                          </>
                        </InfiniteScroll>
                      </>
                    )}
                  </div>

                  <div className="properties-page__container-button">
                    <Button
                      className="properties-page__container-button__button"
                      size="large"
                      type="primary"
                      onClick={() => navigate('/app/createProperty')}
                    >
                      {t('action.newProperty')}
                    </Button>
                  </div>
                </>
              )}
            </>
          )}
          <FilterProperties
            onClose={() => setOpen(false)}
            open={open}
            onClickCleanFilter={() => {
              setScroll(scrollInitial);
              getProperties({ initial: true });
              setOpen(false);
              setFilterProperties({});
            }}
            onFinishForm={(values) => {
              const filter: IPropertyFilterInput = {
                ...values,
                availability: undefined,
              };
              if (values.availability) {
                if (
                  values.availability ===
                  EnumsValues.PropertyOcupationalStatus.Free
                ) {
                  filter.available = true;
                  filter.busy = false;
                } else {
                  filter.busy = true;
                  filter.available = false;
                }
              }
              setFilterProperties(filter);
              setScroll(scrollInitial);
              getProperties({
                initial: true,
                filter,
              });
              setOpen(false);
            }}
          />
        </div>
      </>
    )
  );
};
export default MyPropertiesPage;
